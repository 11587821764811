@import "/src/mixins/mixins.scss";

.wrapper {
  display: flex;
  gap: 12px;
  justify-content: end;

  .custom_btn {
    background: transparent;
    color: #eb1864;
    border: 2px solid #eb1864;
    height: 62px;
    width: 80px;
    padding: 20px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    gap: vw(12px);

    .custom_btn {
      border: vw(2px) solid #eb1864;
      height: vw(62px);
      width: vw(80px);
      padding: vw(20px) vw(24px);
      border-radius: vw(10px);
    }
  }
}
