@import "/src/styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.read_more_description {
  //styleName: Greenwich/ Light 18;
  font-family: Greenwich;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: $text_accent;
  min-height: 150px;

  .see_more_link {
    font-family: Greenwich;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: $pink4;
    text-decoration: underline;
    margin-left: 10px;
  }
}

@media screen and (max-width: 1439px) {
  .read_more_description {
    //styleName: Greenwich/ Light 18;
    font-family: Greenwich;
    font-size: 1.25vw;
    line-height: 21px;
    letter-spacing: 0em;
    margin-bottom: 1.944vw;

    .see_more_link {
      font-family: Greenwich;
      font-size: 1.25vw;
      line-height: 21px;
      margin-left: 5px;
    }
  }
}

@media screen and (max-width: 480px) {
  .read_more_description {
    font-size: vw(18px);
    line-height: vw(21px);
    min-height: vw(100px);

    .see_more_link {
      font-size: vw(18px);
      line-height: vw(21px);
    }
  }
}
