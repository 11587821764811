@import "../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.subtitle_wrapper {
  margin-top: 16px;
}

.bottom_text_wrapper {
  margin-top: 40px;
}

.bottom_text {
  color: #121212;
}

.salon_address_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0;

  h3 {
    font-family: Greenwich;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: $text_accent;
    margin: 0;
  }

  .req_sign {
    margin-left: 8px;
    font-size: 18px;
    line-height: 21px;
    color: $grey2;
  }
}

.address_inputs_wrapper {
  .long_input {
    width: 100%;
  }

  .short_input {
    width: calc(25% - 10px);
  }
}

@media screen and (max-width: 480px) {
  .salon_address_wrapper {
    gap: vw(8px);
    margin: vw(16px) 0;

    h3 {
      font-size: vw(20px);
      line-height: vw(24px);
    }
  }

  .bottom_text_wrapper {
    margin-top: vw(40px);
  }
}
