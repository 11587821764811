@import "../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.beautyArtists_wrapper {
  padding-left: 80px;
  padding-right: 80px;
}

.top_section_wrapper {
  padding-top: 98px;
  position: relative;
  display: flex;
  justify-content: space-between;

  &::after {
    content: "";
    background: linear-gradient(90.4deg, #382c5d 6.97%, #282582 95.29%);
    height: 298px;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }
}

.titles_wrapper {
  display: flex;
  flex-direction: column;
  gap: 72px;

  button {
    width: 335px;
  }
}

.top_section_title {
  /* Greenwich/SEMIBOLD 44 */
  font-family: Greenwich SemBd;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  text-transform: uppercase;
  color: $white;
}

.top_section_sub_title {
  font-family: Greenwich SemBd;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 525px;
}

.top_section_image_wrapper {
  position: relative;
}

.colored_blocks_wrapper {
  position: absolute;
  left: -172px;
  display: flex;
  gap: 14px;

  div {
    background-color: $purple2;
    width: 68px;
    height: 68px;
    border-radius: 8px;
  }

  div:last-child {
    background-color: $purple3;
  }
}

.who_vervvey_wrapper {
  margin-bottom: 100px;

  .who_is_vv_for {
    padding: 100px 0px;
  }
}

.who_vervvey_text {
  font-family: Greenwich ExtLt;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: $text_accent;
  width: 1100px;
  margin: 0 auto;
}

.used_by_wrapper {
  padding-bottom: 100px;
}

.divider {
  height: 3px;
  background-color: $pink4;
  opacity: 0.3;
  width: 820px;
  margin: 12px auto 50px;
}

.used_by_title {
  font-family: Greenwich SemBd;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  color: $text_accent;
}

.how_vv_works {
  margin-bottom: 100px;
}

.btn_wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;

  .btn {
    width: 540px;
    text-transform: none;

    &:global.btn-secondary {
      background-color: $blu4;
    }
  }
}

@media screen and (max-width: 1439px) {
  .beautyArtists_wrapper {
    padding-left: vw(80px, 1440px);
    padding-right: vw(80px, 1440px);
  }

  .top_section_wrapper {
    padding-top: vw(98px, 1440px);

    &::after {
      height: vw(298px, 1440px);
    }
  }

  .titles_wrapper {
    gap: vw(72px, 1440px);

    button {
      width: vw(335px, 1440px);
    }
  }

  .top_section_title {
    font-size: vw(44px, 1440px);
    line-height: vw(56px, 1440px);
  }

  .top_section_sub_title {
    font-size: vw(20px, 1440px);
    width: vw(525px, 1440px);
  }

  .colored_blocks_wrapper {
    left: vw(-172px, 1440px);
    gap: vw(14px, 1440px);

    div {
      width: vw(68px, 1440px);
      height: vw(68px, 1440px);
    }
  }

  .who_vervvey_wrapper {
    margin-bottom: vw(100px, 1440px);
  }

  .who_vervvey_text {
    font-size: vw(26px, 1440px);
    line-height: vw(33px, 1440px);
    width: vw(1100px, 1440px);
  }

  .used_by_wrapper {
    padding-bottom: vw(100px, 1440px);
  }

  .divider {
    height: 3px;
    width: vw(820px, 1440px);
    margin: vw(12px, 1440px) auto vw(50px, 1440px);
  }

  .used_by_title {
    font-size: vw(44px, 1440px);
    line-height: vw(56px, 1440px);
  }

  .how_vv_works {
    margin-bottom: vw(100px, 1440px);
  }
}

@media screen and (max-width: 480px) {
  .beautyArtists_wrapper {
    max-width: 100vw;
    overflow: hidden;
    padding: 0 vw(20px);
  }

  .top_section_wrapper {
    padding-top: vw(23px);
    position: relative;
    display: flex;
    justify-content: space-between;

    &::after {
      height: vw(174px);
    }
  }

  .titles_wrapper {
    display: flex;
    flex-direction: column;
    gap: vw(32px);

    button {
      width: 100%;
    }
  }

  .top_section_title {
    width: vw(244px);
    font-weight: 400;
    font-size: vw(22px);
    line-height: vw(32px);
  }

  .top_section_sub_title {
    margin-top: vw(16px);
    font-weight: 400;
    font-size: vw(18px);
    line-height: vw(32px);
    width: 100%;
  }

  .top_section_image_wrapper {
    position: absolute;
    right: 0;

    img {
      width: vw(160px);
      height: vw(160px);
    }
  }

  .colored_blocks_wrapper {
    display: none;
  }

  .who_vervvey_wrapper {
    margin-bottom: vw(60px);

    .who_is_vv_for {
      padding: vw(120px) 0 vw(60px);
    }
  }

  .who_vervvey_text {
    font-size: vw(18px);
    line-height: vw(20px);
    width: 100%;
  }

  .used_by_wrapper {
    margin-left: vw(-20px);
    width: 100vw;
    padding-bottom: vw(60px);
  }

  .divider {
    height: vw(3px);
    margin: vw(12px) auto vw(32px);
  }

  .used_by_title {
    font-weight: 400;
    font-size: vw(30px);
    line-height: vw(38px);
  }

  .how_vv_works {
    margin-bottom: vw(60px);
  }

  .btn_wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 100px;

    .btn {
      width: 100%;
      text-transform: none;
    }
  }
}
