@import "../../../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.who_is_vv_for {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 100px 80px;
  gap: 60px;

  .title {
    //styleName: Greenwich/SEMIBOLD 44;
    font-family: Greenwich SemBd;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: center;
    color: $text_accent;
    text-transform: uppercase;
    padding: 10px 0px;
  }

  .vv_divider {
    .divider {
      border-bottom: 3px solid $blu4;
    }
  }

  .slider {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & .swiper {
      margin: 0 auto;
      width: 80%;
    }

    .card_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .label {
        //styleName: Greenwich/Reg 24;
        font-family: Greenwich;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1439px) {
  .who_is_vv_for {
    padding: vw(100px, 1440px) vw(80px, 1440px);
    gap: vw(60px, 1440px);

    .title {
      font-size: 3.056vw;
      line-height: 3.889vw;
      padding: 0.694vw 0;
    }

    .slider {
      .card_wrapper {
        gap: 1.042vw;
      }
    }
  }

  .slider_card_img {
    width: 5.833vw;
  }
}

@media screen and (max-width: 480px) {
  .who_is_vv_for {
    .title {
      font-size: vw(30px);
      line-height: vw(38px);
      letter-spacing: 0.05em;
    }

    .slider {
      width: 100%;
      .card_wrapper {
        width: vw(100px);

        img {
          width: vw(62px);
          height: vw(62px);
        }

        .label {
          font-weight: 400;
          font-size: vw(16px);
          line-height: vw(19px);
          text-align: center;
          width: 80%;
        }
      }
    }
  }
}
