@import "../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.switcherWrapper {
  margin-top: 35.5px;
  display: flex;
  gap: 16px;
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $text_accent;
}

.week_wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.day_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.day_title {
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $text_accent;
}

.time_values_wrapper {
  display: flex;
  gap: 24px;
}

.same_opening_hours_wrapper {
  margin-top: 36px;
}

.working_days_title {
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $text_accent;
}

.days_wrapper {
  width: 540px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.salon_hours_availability_wrapper {
  margin-top: 40px;

  .salon_hours_availability_title {
    display: flex;
    align-items: center;
    gap: 20px;
    //styleName: Greenwich/Reg 18;
    font-family: Greenwich;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $text_accent;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 480px) {
  .switcherWrapper {
    margin-top: vw(35.5px);
    gap: vw(16px);
    font-size: vw(20px);
    line-height: vw(24px);
  }

  .week_wrapper {
    margin-top: vw(30px);
    gap: vw(24px);
  }

  .day_wrapper {
    gap: vw(16px);
  }

  .day_title {
    font-size: vw(20px);
    line-height: vw(23px);
  }

  .time_values_wrapper {
    display: flex;
    flex-direction: column;
    gap: vw(20px);
  }

  .same_opening_hours_wrapper {
    margin-top: vw(36px);
  }

  .working_days_title {
    margin-top: vw(40px);
    margin-bottom: vw(20px);
    font-size: vw(20px);
    line-height: vw(23px);
  }

  .days_wrapper {
    width: 100%;
    gap: vw(20px);
  }
}
