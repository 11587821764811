@import "../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.vv_footer {
  position: relative;

  &::after {
    content: "";
    background: linear-gradient(90.4deg, #382c5d 6.97%, #282582 95.29%),
      linear-gradient(0deg, #382c5d, #382c5d);
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }

  .with_links {
    display: flex;
    justify-content: space-between;
    padding: 40px 80px 50px 80px;

    .links_list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .list_item {
        a {
          font-family: Greenwich;
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          color: $blu2;

          &:hover {
            color: $pink2;
          }
        }
      }
    }

    .links_wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .social_lins_wrapper {
        display: flex;
        gap: 10px;
      }

      .email_link,
      .phone_number {
        a {
          font-family: Greenwich;
          font-size: 24px;
          font-weight: 400;
          line-height: 28px;
          color: $blu2;
        }
      }
    }

    .image_wrapper {
      img {
        width: 250px;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    padding: 20px 80px;
    color: $grey;
    border-top: 2px solid rgba(255, 255, 255, 0.1);

    .terms {
      display: flex;
      gap: 30px;

      a {
        font-family: Greenwich;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        color: $grey;
      }
    }

    .learn_about {
      display: flex;
      align-items: center;

      a {
        //styleName: Greenwich/Reg 18;
        font-family: Greenwich;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: $grey;
        display: flex;
        align-items: center;
        gap: 5px;

        .arrow_right_icon {
          width: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .vv_footer {
    .bottom {
      padding: vw(20px);
    }

    .with_links {
      padding: vw(24px) vw(20px);

      .links_list .list_item a {
        font-size: vw(16px);
        line-height: vw(19px);
      }

      .links_wrapper {
        .email_link,
        .phone_number {
          a {
            font-size: vw(14px);
            line-height: vw(16px);
          }
        }

        .social_lins_wrapper .social_link {
          width: vw(32px);
        }

        .social_lins_wrapper {
          gap: vw(12px);
        }
      }

      .image_wrapper img {
        width: vw(136px);
        height: vw(41px);
      }
    }

    .bottom .terms a {
      font-size: vw(14px);
      line-height: vw(16px);
    }
  }
}
