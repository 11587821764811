@import "./../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.main_wrapper {
  width: 100%;
}

.input_wrapper {
}

.input_wrapper {
}

.label_wrapper {
  //styleName: Greenwich/Reg 18;
  font-family: Greenwich;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: $grey2;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.err_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-top: 8px;
}

.text_icon_wrapper {
  display: flex;
  gap: 8px;
}

@media screen and (max-width: 480px) {
  .label_wrapper {
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .form-control {
    background-color: red;
  }
}
