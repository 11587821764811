@import "../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.page_title {
  //styleName: Greenwich/SemiBold 36;
  font-family: Greenwich SemBd;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $blu4;
  width: 100%;
  margin-bottom: 30px;
}

.title_alignment {
  margin-top: 24px;
}

.form_wrapper {
  margin-top: 23px;
}

.select_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.select_label {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $grey2;
}

.select_style {
  height: 60px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 18px 16px 18px 0px;

  &:hover {
    border: 1px solid $text_accent;
  }
}

.select_group {
  display: flex;
  gap: 12px;
}

.wrapper_for_selects {
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-bottom: 52px;
}

.select_flex_wrapper {
  display: flex;
  gap: 12px;
}

.checkboxes {
  margin-bottom: 94px;
}

.checkboxes_wrapper {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
}

.err_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-top: 8px;
}

.select_country {
  width: calc(50% - 6px);
}

.select_describe {
  width: calc(50% - 6px);
}

.btn_bottom_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  .cancel_btn {
    width: 260px;
  }
}

@media screen and (max-width: 480px) {
  .page_title {
    margin-bottom: vw(16px);
    font-size: vw(28px);
    line-height: vw(40px);
  }

  .title {
    margin-bottom: vw(31px);
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .wrapper_for_selects {
    gap: vw(23px);
    margin-bottom: vw(40px);

    .select_country {
      width: 100%;
    }

    .title_alignment {
      margin-top: vw(17px);
      margin-bottom: vw(8px);
    }

    .select_flex_wrapper {
      flex-direction: column;
      gap: vw(23px);
    }

    .select_describe {
      width: 100%;
    }
  }

  .checkboxes {
    margin-bottom: vw(94px);
  }

  .checkboxes_wrapper {
    margin-top: 0;
    gap: vw(20px);
  }

  .err_text {
    font-size: vw(18px);
    line-height: vw(21px);
    margin-top: vw(8px);
  }
}
