@import "../../styles/global-variables.scss";

.not_found_page {
  .header_image_block {
    padding: 40px 80px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    position: relative;
    z-index: 0;

    &::after {
      content: "";
      background: linear-gradient(90.4deg, #382c5d 6.97%, #282582 95.29%),
        #382c5d;
      height: 100%;
      left: 50%;
      margin-left: -50vw;
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: -999;
    }

    .polygon {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background-color: $purple3;
      opacity: 0.5;
      clip-path: polygon(94% 0, 100% 0, 100% 100%, 74% 100%);
    }

    .row_container {
      display: flex;
      justify-content: space-between;
      z-index: 1;

      .image_column {
        img {
          width: 100%;
        }
      }
    }

    .text_column {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h1 {
        font-family: Greenwich;
        font-size: 72px;
        font-style: normal;
        font-weight: 600;
        line-height: 92px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: $white;
      }

      h2 {
        font-family: Greenwich SemBd;
        font-size: 46px;
        font-style: normal;
        font-weight: 600;
        line-height: 59px;
        letter-spacing: 0em;
        text-align: center;
        text-transform: uppercase;
        color: $white;
      }
    }
  }

  .main_body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;
    position: relative;

    &::after {
      content: "";
      background-color: #e6f0ff;
      height: 100%;
      left: 50%;
      margin-left: -50vw;
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: -999;
    }

    .text {
      font-family: Greenwich;
      font-size: 38px;
      font-style: normal;
      font-weight: 600;
      line-height: 49px;
      letter-spacing: 0em;
      text-align: center;
      text-transform: uppercase;
      color: $text_accent;

      a {
        color: $pink4;
      }
    }
  }
}

@media screen and (max-width: 1439px) {
  .not_found_page {
    .header_image_block {
      padding: 2.778vw 5.556vw;

      .text_column {
        gap: 0.694vw;

        h1 {
          font-size: 5vw;
          line-height: 6.389vw;
        }

        h2 {
          font-size: 3.194vw;
          line-height: 4.097vw;
        }
      }
    }

    .main_body {
      padding: 5.556vw;

      .text {
        font-size: 2.639vw;
        line-height: 3.403vw;
      }
    }
  }
}
