@import "/src/styles/global-variables";
@import "/src/mixins/mixins.scss";

.page_title {
  //styleName: Greenwich/SemiBold 36;
  font-family: Greenwich SemBd;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}

.title {
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $blu4;
  width: 100%;
  margin-bottom: 30px;
}

.inputs_wrapper {
  margin-bottom: 94px;
}

.top_input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.input_style {
  width: 100%;
}

.select_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.select_label {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $grey2;
}

.select_style {
  height: 60px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 18px 16px 18px 0px;

  &:hover {
    border: 1px solid $text_accent;
  }
}

.select_group {
  display: flex;
  gap: 12px;
}

.textarea_group {
  margin-top: 23px;

  label {
    //styleName: Greenwich/Reg 18;
    font-family: Greenwich;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $grey2;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  textarea {
    height: 146px;
    width: 100%;
    font-size: 24px;
    font-weight: 400;
    padding: 15px;
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    box-shadow: inset 0 0 0 50px #fff;
    resize: none;

    &:hover {
      border: 1px solid $text_accent;
    }

    &:focus {
      outline: none;
      border: 2px solid $text_accent;
    }
  }
}

.btn_bottom_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 94px;

  button {
    width: 386px;
  }

  .cancel_btn {
    width: 260px;
  }
}

@media screen and (max-width: 480px) {
  .top_input_wrapper {
    gap: vw(24px);
  }

  .page_title {
    margin-bottom: vw(16px);
    font-size: vw(28px);
    line-height: vw(40px);
    font-weight: 600;
  }

  .title {
    margin-bottom: vw(24px);
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .select_group {
    display: flex;
    flex-direction: column;
    gap: vw(24px);
  }

  .btn_bottom_wrapper {
    gap: vw(12px);
    justify-content: space-between;
    margin-top: vw(94px);
    margin-bottom: vw(100px);

    button {
      width: vw(210px);
      justify-self: flex-end;
    }

    .submit_btn {
      width: vw(210px);
      padding: vw(21px) vw(28px);
      height: vw(62px);
      font-size: vw(18px);
      line-height: vw(18px);
    }

    .cancel_btn {
      width: vw(210px);
      padding: vw(21px) vw(28px);
      height: vw(62px);
      font-size: vw(18px);
      line-height: vw(18px);
    }
  }
}
