@import "../../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.top_section_image {
  width: 432px;
  height: 432px;
  border-radius: 12px;
  object-fit: cover;
}

@media screen and (max-width: 1439px) {
  .top_section_image {
    width: vw(432px, 1440px);
    height: vw(432px, 1440px);
  }
}
