@import "../../styles/global-variables.scss";

.notification_wrapper {
  display: flex;
  flex-direction: column;
  width: 600px;

  .body {
    background-color: $blu1;
    padding: 40px;

    .welcome_text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $text_accent;
      margin-bottom: 40px;
    }

    .bonus_text {
      font-family: Verdana;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: $purple1;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .complete_profile_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 44px;
      background-color: $white;
      border-radius: 20px;
      padding: 56px 30px;
      margin-bottom: 40px;

      .title {
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .complete_btn {
      border: 0;
      background-color: $pink4;
      border-radius: 10px;
      height: 50px;
      width: 238px;
      font-family: Verdana;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      color: $white;
    }

    .profile_url_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: $white;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      padding: 40px;
      margin: 0 -40px;

      .profile_url {
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: $pink4;
      }
    }

    .best_process_text {
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: justified;
      margin-bottom: 70px;
    }

    .with_love {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $text_accent;
    }
  }

  .color_line {
    display: flex;
    justify-content: center;
    height: 6px;
    background-color: #cde1ff;
    margin: 0 -40px;

    .red_line {
      background-color: $pink4;
      height: 100%;
      width: 210px;
    }

    .rectangle_left {
      width: 6px;
      height: 6px;
      background-color: $pink4;
      clip-path: polygon(100% 0, 100% 100%, 0 100%);
    }

    .rectangle_right {
      width: 6px;
      height: 6px;
      background-color: $pink4;
      clip-path: polygon(100% 0, 0 0, 0 100%);
    }
  }
}
