@import "../../styles/global-variables.scss";

.vv_divider {
  display: flex;
  justify-content: center;

  .divider {
    width: 57vw;
    max-width: 820px;
    opacity: 0.3;
    border-bottom: 3px solid $pink4;
  }
}
