@import "../../../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.logout_modal {
  .modal-dialog {
    max-width: 716px;

    .modal-header {
      padding: 24px 24px 0px 24px;
      border: 0;

      .btn-close {
        color: $grey;
      }
    }

    .modal-content {
      background-color: $blu1;
      border-radius: 20px;
    }

    .modal-body {
      padding: 0px 40px;
      display: flex;
      flex-direction: column;
      gap: 80px;

      .title {
        //styleName: Greenwich/BOLD 32;
        font-family: Greenwich;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: $text_accent;
        padding-bottom: 8px;
      }

      .sub-title {
        font-family: Greenwich;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $text_accent;
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }

      .btn {
        width: 340px;
      }
    }

    .modal-footer {
      border: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .logout_modal {
    .modal-dialog {
      width: vw(440px);

      .modal-body {
        padding: vw(20px) vw(24px);
        gap: vw(52px);

        .title {
          width: vw(320px);
          font-size: vw(18px);
          line-height: vw(18px);
          padding-bottom: vw(8px);
        }

        .sub-title {
          font-size: vw(12px);
          line-height: vw(12px);
        }
      }

      .modal-footer {
        padding-bottom: vw(8px);
      }
    }
  }

  .vv_button.btn.btn-outline-primary {
    height: vw(52px);
    font-size: vw(26px);
    line-height: vw(33px);
  }

  .vv_button.btn.btn-primary {
    height: vw(52px);
    font-size: vw(26px);
    line-height: vw(33px);
  }
}
