@import "../../styles/global-variables";

.form-control {
  box-sizing: border-box;
}

.form-control:focus {
  box-shadow: 0 0 0 1px $blu4;
}

.react-tel-input .form-control {
  padding-left: 56px;
}

.react-tel-input .selected-flag .arrow {
  margin-left: 5px;
  margin-bottom: 4px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #eb1864;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 8px solid #eb1864;
}

.react-tel-input .country-list {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: $text_accent;
  padding-top: 12px;
  padding-left: 12px;

  & :hover {
    background-color: transparent;
  }

  & ::-webkit-scrollbar {
    width: 8px;
  }
  & ::-webkit-scrollbar-track {
    background-color: $white;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: $blu1;
  }
}

.react-tel-input .country-list .country.highlight {
  background-color: transparent;
}

.react-tel-input .country-list .country:hover {
  background-color: transparent;
}

.country-list ::-webkit-scrollbar {
  width: 8px;
}

.country-list ::-webkit-scrollbar-track {
  background-color: $blu1;
}

.country-list ::-webkit-scrollbar-thumb {
  background-color: red;
}

.react-tel-input .selected-flag .arrow {
  background-color: transparent;
}
