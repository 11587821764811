@import "../../../styles/global-variables.scss";

.burger_menu_btn {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  transform-origin: 16px 11px;
  gap: 5px;
  outline: 0;
  border: 0;
  padding: 0px;
  background: none;

  span {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:nth-of-type(1) {
      width: 32px;
    }

    &:nth-of-type(2) {
      width: 32px;
    }

    &:nth-of-type(3) {
      width: 32px;
    }
  }

  :focus {
    border: medium none #23a2d3;
    outline: 0;
  }

  &.active {
    span:nth-of-type(1) {
      transform: rotate(45deg) translate(7px, 6px);
      width: 24px;
    }

    span:nth-of-type(2) {
      opacity: 0;
      pointer-events: none;
    }

    span:nth-of-type(3) {
      transform: rotate(-45deg) translate(5px, -4px);
      width: 24px;
    }
  }

  span {
    display: block;
    width: 24px;
    height: 3px;
    background-color: #79a7ec;

    &.active {
      background-color: #ff669d;
      border-radius: 10px;
    }
  }
}
