@import "../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.custom_checkbox_wrapper {
  height: 52px;
  outline: 2px solid $pink4;
  border-radius: 10px;

  &.disabled {
    outline: 2px solid $grey;

    .inner_checkbox {
      cursor: not-allowed;
      color: $grey;
    }
  }

  .inner_checkbox {
    padding-left: 21px;
    padding-right: 21px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    //styleName: Greenwich/Reg 20;
    font-family: Greenwich;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: $pink4;
    cursor: pointer;
    user-select: none;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + span {
    cursor: pointer;
  }

  input[type="checkbox"]:checked + div {
    background-color: $pink4;
    border-color: $pink4;
    color: $white;
    font-weight: 700;
  }
}

@media screen and (max-width: 480px) {
  .custom_checkbox_wrapper {
    height: vw(52px);
    border-radius: vw(10px);
    outline-width: vw(2px);

    &.disabled {
      outline-width: vw(2px);
    }

    .inner_checkbox {
      border-radius: vw(10px);
      padding: vw(16px) vw(24px);
      font-size: vw(20px);
      line-height: vw(20px);
    }
  }
}
