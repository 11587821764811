@import "./../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.custom_input_wrapper {
  width: 100%;
}

.input_group {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    //styleName: Greenwich/Reg 18;
    font-family: Greenwich;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $text_accent;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  input {
    height: 60px;
    font-size: 24px;
    font-weight: 400;
    padding: 15px;
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    box-shadow: inset 0 0 0 50px #fff;

    &::placeholder {
      /* Greenwich/Reg 18 */

      font-family: Greenwich;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */

      /* Grey 1 */

      color: $grey;
    }

    &:focus {
      outline: none;
      border: 2px solid $blu4;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.err_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-top: 8px;
}

.text_icon_wrapper {
  display: flex;
  gap: 8px;
  line-height: 1;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .input_group {
    label {
      font-size: vw(18px);
      line-height: vw(21px);
      margin-bottom: vw(8px);
    }

    input {
      height: vw(60px);
      font-size: vw(20px);
      line-height: vw(20px);
      border-radius: vw(8px);

      &::placeholder {
        font-size: vw(18px);
        line-height: vw(21px);
      }
    }
  }

  .err_text {
    font-size: vw(18px);
    line-height: vw(21px);
    margin-top: vw(8px);
  }
}
