@import "../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.page_title {
  //styleName: Greenwich/SemiBold 36;
  font-family: Greenwich SemBd;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}

.title {
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $blu4;
  width: 100%;
  margin-bottom: 30px;
}

.title_regular {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  margin-top: 12px;
}

.diplomas_wrapper {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.certificate_card {
  padding: 40px;
  border-radius: 20px;
  border: 2px solid $white;
}

.certificate_card_upload {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  border-radius: 20px;
  border: 2px solid $white;

  .organization_name_input {
    width: 50%;
  }

  .certificate_upload_title {
    //styleName: Greenwich/Reg 18;
    font-family: Greenwich;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $text_accent;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
}

.item_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.add_wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  margin-top: 40px;

  img {
    padding-left: 5px;
  }

  div {
    font-family: Greenwich;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: $pink4;
    text-decoration: underline;
  }
}

.delete_btn_wrapper {
  display: flex;
  justify-content: end;
}

.delete_btn {
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: transparent;
  border: none;
}

.upload_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.camera_wrapper {
  background-color: $white;
  width: 216px;
  height: 216px;
  border-radius: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
}

.upload_label {
  width: 216px;
  border: none;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: $pink4;
  color: $white;
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 32px;
  border-radius: 10px;
  text-transform: uppercase;

  &.disabled {
    pointer-events: none;
    opacity: 0.65;
  }

  input {
    display: none;
  }
}

.photos_wrapper {
  display: flex;
  gap: 58px;
  flex-wrap: wrap;
  margin-bottom: 94px;

  img {
    border-radius: 20px;
    object-fit: cover;
  }
}

.photo_wrapper {
  width: 216px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;

  img {
    width: 100%;
    height: 216px;
    object-fit: cover;
  }

  button {
    width: 100%;
    height: 62px;
    font-family: Greenwich;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $pink4;
    border: 2px solid $pink4;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
}

.btn_bottom_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 94px;
  gap: 12px;
}

.err_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
}

@media screen and (max-width: 480px) {
  .page_title {
    margin-bottom: vw(16px);
    font-size: vw(28px);
    line-height: vw(40px);
  }

  .title {
    margin-bottom: vw(24px);
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .diplomas_wrapper {
    margin-top: 0;
    gap: vw(16px);
  }

  .certificate_card_upload {
    padding: vw(32px) vw(16px);

    .organization_name_input {
      width: 100%;
    }

    .certificate_upload_title {
      width: vw(194px);
      gap: vw(8px);
      font-size: vw(18px);
      line-height: vw(21px);
    }

    .upload_wrapper {
      width: vw(194px);

      .camera_wrapper {
        width: vw(194px);
        height: vw(194px);
      }

      .upload_label {
        width: 100%;
        padding: vw(21px) vw(21px);
        height: vw(62px);
        font-size: vw(18px);
        line-height: vw(18px);
      }
    }
  }

  .certificate_card {
    padding: vw(32px) vw(16px);

    .item_wrapper {
      flex-direction: column;

      .camera_wrapper {
        width: vw(194px);
        height: vw(194px);
      }
    }
  }
}
