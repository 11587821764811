@import "/src/styles/global-variables";
@import "/src/mixins/mixins.scss";

.upload_wrapper {
  width: 216px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .upload_title {
    //styleName: Greenwich/Reg 18;
    font-family: Greenwich;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    gap: 16px;
    width: 290px;
  }

  button {
    width: 100%;
    height: 62px;
    font-family: Greenwich;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $pink4;
    border: 2px solid $pink4;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
}

.camera_wrapper {
  background-color: $white;
  width: 216px;
  height: 216px;
  border-radius: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
}

.upload_label {
  width: 216px;
  border: none;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: $pink4;
  color: $white;
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 32px;
  border-radius: 10px;
  text-transform: uppercase;

  input {
    display: none;
  }
}

.err_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-top: 8px;
}

@media screen and (max-width: 480px) {
  .upload_wrapper {
    width: vw(210px);
    gap: vw(8px);

    button {
      height: vw(62px);
      font-size: vw(18px);
    }

    .upload_title {
      width: vw(210px);
      font-size: vw(18px);
      line-height: vw(21px);
    }

    .camera_wrapper {
      width: vw(210px);
      height: vw(210px);
    }

    .upload_label {
      width: vw(210px);
      padding: vw(21px) vw(28px);
      height: vw(62px);
      font-size: vw(18px);
    }
  }

  .upload_btn_wrapper {
    button {
      border-radius: vw(10px);
    }
  }
}
