@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-Light.eot");
  src: local("Greenwich-Light"),
    url("Greenwich-Light.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-Light.woff2") format("woff2"),
    url("Greenwich-Light.woff") format("woff"),
    url("Greenwich-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Greenwich ExtBd";
  src: url("Greenwich-ExtraBoldItalic.eot");
  src: local("Greenwich-ExtraBoldItalic"),
    url("Greenwich-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-ExtraBoldItalic.woff2") format("woff2"),
    url("Greenwich-ExtraBoldItalic.woff") format("woff"),
    url("Greenwich-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-Italic.eot");
  src: local("Greenwich-Italic"),
    url("Greenwich-Italic.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-Italic.woff2") format("woff2"),
    url("Greenwich-Italic.woff") format("woff"),
    url("Greenwich-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Greenwich SemBd";
  src: url("Greenwich-SemiBoldItalic.eot");
  src: local("Greenwich-SemiBoldItalic"),
    url("Greenwich-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-SemiBoldItalic.woff2") format("woff2"),
    url("Greenwich-SemiBoldItalic.woff") format("woff"),
    url("Greenwich-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Greenwich SemBd";
  src: url("Greenwich-SemiBold.eot");
  src: local("Greenwich SemBd"),
    url("Greenwich-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-SemiBold.woff2") format("woff2"),
    url("Greenwich-SemiBold.woff") format("woff"),
    url("Greenwich-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Greenwich Med";
  src: url("Greenwich-Medium.eot");
  src: local("Greenwich-Medium"),
    url("Greenwich-Medium.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-Medium.woff2") format("woff2"),
    url("Greenwich-Medium.woff") format("woff"),
    url("Greenwich-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Greenwich ExtLt";
  src: url("Greenwich-ExtraLightItalic.eot");
  src: local("Greenwich-ExtraLightItalic"),
    url("Greenwich-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-ExtraLightItalic.woff2") format("woff2"),
    url("Greenwich-ExtraLightItalic.woff") format("woff"),
    url("Greenwich-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-ThinItalic.eot");
  src: local("Greenwich-ThinItalic"),
    url("Greenwich-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-ThinItalic.woff2") format("woff2"),
    url("Greenwich-ThinItalic.woff") format("woff"),
    url("Greenwich-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Greenwich ExtBd";
  src: url("Greenwich-ExtraBold.eot");
  src: local("Greenwich-ExtraBold"),
    url("Greenwich-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-ExtraBold.woff2") format("woff2"),
    url("Greenwich-ExtraBold.woff") format("woff"),
    url("Greenwich-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-Thin.eot");
  src: local("Greenwich-Thin"),
    url("Greenwich-Thin.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-Thin.woff2") format("woff2"),
    url("Greenwich-Thin.woff") format("woff"),
    url("Greenwich-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Greenwich Med";
  src: url("Greenwich-MediumItalic.eot");
  src: local("Greenwich-MediumItalic"),
    url("Greenwich-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-MediumItalic.woff2") format("woff2"),
    url("Greenwich-MediumItalic.woff") format("woff"),
    url("Greenwich-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-BlackItalic.eot");
  src: local("Greenwich-BlackItalic"),
    url("Greenwich-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-BlackItalic.woff2") format("woff2"),
    url("Greenwich-BlackItalic.woff") format("woff"),
    url("Greenwich-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-Regular.eot");
  src: local("Greenwich-Regular"),
    url("Greenwich-Regular.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-Regular.woff2") format("woff2"),
    url("Greenwich-Regular.woff") format("woff"),
    url("Greenwich-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-LightItalic.eot");
  src: local("Greenwich-LightItalic"),
    url("Greenwich-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-LightItalic.woff2") format("woff2"),
    url("Greenwich-LightItalic.woff") format("woff"),
    url("Greenwich-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-Bold.eot");
  src: local("Greenwich-Bold"),
    url("Greenwich-Bold.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-Bold.woff2") format("woff2"),
    url("Greenwich-Bold.woff") format("woff"),
    url("Greenwich-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-BoldItalic.eot");
  src: local("Greenwich-BoldItalic"),
    url("Greenwich-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-BoldItalic.woff2") format("woff2"),
    url("Greenwich-BoldItalic.woff") format("woff"),
    url("Greenwich-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Greenwich ExtLt";
  src: url("Greenwich-ExtraLight.eot");
  src: local("Greenwich-ExtraLight"),
    url("Greenwich-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-ExtraLight.woff2") format("woff2"),
    url("Greenwich-ExtraLight.woff") format("woff"),
    url("Greenwich-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Greenwich";
  src: url("Greenwich-Black.eot");
  src: local("Greenwich-Black"),
    url("Greenwich-Black.eot?#iefix") format("embedded-opentype"),
    url("Greenwich-Black.woff2") format("woff2"),
    url("Greenwich-Black.woff") format("woff"),
    url("Greenwich-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
