@import "../../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.vv_arrow_btn {
  cursor: pointer;
  border: 0;
  background-color: rgb(255, 255, 255, 0.4);
  border-radius: 50%;
  position: absolute;
  width: 100px;
  height: 100px;

  &.left {
    left: 25px;
  }

  &.right {
    right: 25px;
  }
}

@media screen and (max-width: 1439px) {
  .vv_arrow_btn {
    width: 6.944vw;
    height: 6.944vw;

    &.left {
      left: 1.736vw;
    }

    &.right {
      right: 1.736vw;
    }
  }
}

@media screen and (max-width: 480px) {
  .vv_arrow_btn {
    width: vw(60px);
    height: vw(60px);

    img {
      width: vw(32px);
    }
  }
}
