@import "../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.checkboxes_wrapper {
  margin-top: 31px;
  margin-bottom: 31px;
}

.checkboxes_title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  p {
    margin-right: 8px;
    font-family: Greenwich;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: $text_accent;
    margin-bottom: 0;
  }

  .req_sign {
    font-family: "Greenwich";
    font-style: normal;
    font-weight: 400;
    margin-left: 16px;
    font-size: 18px;
    line-height: 21px;
    color: $grey2;
  }
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
}

.coworking_collaboration_wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.coworking_collaboration_title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  p {
    margin-right: 10px;
    font-family: Greenwich;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: $text_accent;
    margin-bottom: 0;
  }

  .req_sign {
    font-family: "Greenwich";
    font-style: normal;
    font-weight: 400;
    margin-left: 18px;
    font-size: 18px;
    line-height: 21px;
    color: $grey2;
  }
}

.coworking_collaboration_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $text_accent;
}

.coworking_collaboration_checkbox {
  margin-top: 16px;
}

.specific_information_checkbox {
  margin-top: 16px;
}

.text_area {
  label {
    font-weight: 700;
  }
}

.err_text {
  font-family: "Greenwich";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-top: 8px;
}

@media screen and (max-width: 480px) {
  .checkboxes_wrapper {
    margin-top: vw(32px);
    margin-bottom: vw(16px);
  }

  .checkboxes_title {
    margin-bottom: vw(16px);

    p {
      margin-right: vw(8px);
      font-size: vw(18px);
      line-height: vw(21px);
      margin-bottom: 0;
    }

    .req_sign {
      margin-left: vw(16px);
      font-size: vw(18px);
      line-height: vw(21px);
    }
  }

  .checkboxes {
    gap: vw(20px);
  }

  .coworking_collaboration_wrapper {
    margin-top: vw(40px);
    margin-bottom: vw(40px);
  }

  .coworking_collaboration_title {
    margin-bottom: vw(16px);

    p {
      margin-right: vw(10px);
      font-size: vw(20px);
      line-height: vw(23px);
      margin-bottom: 0;
    }

    .req_sign {
      margin-left: vw(18px);
      font-size: vw(18px);
      line-height: vw(21px);
    }
  }

  .coworking_collaboration_text {
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .coworking_collaboration_checkbox {
    margin-top: vw(16px);
  }

  .specific_information_checkbox {
    margin-top: vw(16px);
  }

  .text_area {
    label {
      font-weight: 700;
    }
  }

  .checkboxes_err_text {
    font-size: vw(18px);
    line-height: vw(21px);
    margin-top: vw(8px);
  }
}
