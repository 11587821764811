@import "../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.salon_edit_wrapper {
  position: relative;

  &::after {
    content: "";
    background-color: $blu1;
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }
}

.salon_max_width {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 79px;
  padding-bottom: 100px;
}

.flex_wrapper {
  display: flex;
  gap: 73px;
}

.back_wrapper {
  display: flex;
  gap: 15px;
  margin-bottom: 42px;
  align-items: center;
}

.back_arrow {
  width: 24px;
  height: 24px;
}

.back_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $grey3;
}

.nav_list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 331px;

  li {
    a {
      display: flex;
      gap: 10px;
      text-decoration: none;
      font-family: Greenwich;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: $text_accent;

      &:global.active {
        font-family: Greenwich;
        font-style: normal;
        font-weight: bold;
        color: $pink4;

        div {
          font-weight: 700;
          background-color: $pink4;
          color: $white;
        }
      }

      .number_icon {
        //styleName: Greenwich/Reg 16;
        font-family: Greenwich;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        background-color: $white;
        border-radius: 8px;
        padding: 5px 10px;
        color: $text_accent;
      }
    }
  }
}

.salons_outlet_wrapper {
  width: 880px;
}

.page_main_title {
  /* Greenwich/SemiBold 36 */
  font-family: Greenwich SemBd;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  /* identical to box height */
  color: $text_accent;
  margin: 0;
  padding: 0;
}

.page_blue_subtitle {
  /* Greenwich/Bold 20 */
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $blu4;
  margin: 16px 0 0;
  padding: 0;
}

.form_group {
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.inputs_group {
  display: flex;
  gap: 12px;
  width: 100%;
}

.btn_bottom_wrapper {
  margin-top: 94px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.nav_list li {
  .active {
    color: $pink4;
    font-weight: 700;

    .number_icon {
      background-color: $pink4;
      color: $blu1;
      font-weight: 700;
    }
  }
}

.btn_bottom_wrapper {
  margin-top: 94px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

@media screen and (max-width: 480px) {
  .form_group {
    display: flex;
    flex-direction: column;
    margin-top: vw(31px);
    gap: vw(23px);
  }

  .inputs_group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: vw(16px);

    .half-width {
      width: vw(210px);
    }
  }

  .inputs_group.xs-column {
    display: flex;
    flex-direction: column;
    gap: vw(23px);
  }

  .page_main_title {
    font-size: vw(28px);
    line-height: vw(40px);
  }

  .page_blue_subtitle {
    font-size: vw(18px);
    line-height: vw(21px);
    margin: vw(16px) 0 0;
  }

  .page_subtitle {
    font-size: vw(18px);
    line-height: vw(21px);
    margin: vw(16px) 0 0;
  }

  .salons_outlet_wrapper {
    width: 100%;
  }

  .salon_max_width {
    max-width: 100vw;
    overflow: hidden;
    margin: 0 auto;
    padding: vw(16px) vw(20px);
  }

  .flex_wrapper {
    display: flex;
    flex-direction: column;
    gap: vw(40px);
  }

  .btn_bottom_wrapper {
    margin-top: vw(94px);
    margin-bottom: vw(100px);
    gap: vw(12px);

    button {
      width: vw(210px);
    }
  }

  .btn_bottom_wrapper {
    margin-top: vw(94px);
    margin-bottom: vw(100px);
    gap: vw(12px);

    button {
      width: vw(210px);
    }
  }
}
