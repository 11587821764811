@import "/src/mixins/mixins.scss";

.main {
  min-height: calc(100vh - 410px);
}

@media screen and (max-width: 480px) {
  .main {
    min-height: auto;
  }
}
