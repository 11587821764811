@import "../../../../../styles/global-variables";

.number_icon {
  //styleName: Greenwich/Reg 16;
  font-family: Greenwich;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  background-color: $white;
  border-radius: 8px;
  width: 28px;
  height: 28px;
  color: $text_accent;
  display: flex;
  justify-content: center;
  align-items: center;

  &__active {
    font-weight: 700;
    background-color: $pink4;
    color: $white;
  }
}
