@import "./../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.custom_text_area_wrapper {
  width: 100%;

  label {
    //styleName: Greenwich/Reg 18;
    font-family: Greenwich;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $text_accent;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  textarea {
    height: 168px;
    width: 100%;
    padding: 15px;
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    box-shadow: inset 0 0 0 50px #fff;
    resize: none;
    //styleName: Greenwich/Reg 18;
    font-family: Greenwich;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    overflow: hidden;

    &::placeholder {
      font-family: Greenwich;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $grey;
    }

    &:focus {
      outline: 1px solid $blu4;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.textarea_wrapper {
  position: relative;
}

.limit_counter {
  /* Greenwich/Reg 16 */

  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: $grey;
  position: absolute;
  right: 8px;
  bottom: 12px;
  z-index: 0;
}

.text_icon_wrapper {
  display: flex;
  gap: 8px;
}

.err_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-top: 8px;
}

@media screen and (max-width: 1440px) {
  .custom_text_area_wrapper {
    label {
      font-size: vw(18px, 1440px);
      line-height: vw(21px, 1440px);
      margin-bottom: vw(8px, 1440px);
    }

    textarea {
      height: vw(168px, 1440px);
      padding: vw(15px, 1440px);
      -webkit-box-shadow: inset 0 0 0 vw(50px, 1440px) #fff;
      box-shadow: inset 0 0 0 vw(50px, 1440px) #fff;
      font-size: vw(16px, 1440px);
      line-height: vw(21px, 1440px);

      &::placeholder {
        font-size: vw(16px, 1440px);
        line-height: vw(19px, 1440px);
      }
    }
  }

  .limit_counter {
    font-size: vw(16px, 1440px);
    line-height: vw(19px, 1440px);
    right: vw(8px, 1440px);
    bottom: vw(12px, 1440px);
  }

  .text_icon_wrapper {
    gap: vw(8px, 1440px);
  }

  .err_text {
    font-size: vw(18px, 1440px);
    line-height: vw(21px, 1440px);
    margin-top: vw(8px, 1440px);
  }
}

@media screen and (max-width: 480px) {
  .custom_text_area_wrapper {
    label {
      font-size: vw(18px);
      line-height: vw(21px);
      margin-bottom: vw(8px);
    }

    textarea {
      font-size: vw(20px);
      line-height: vw(20px);
      border-radius: vw(8px);
    }

    textarea::placeholder {
      font-size: vw(16px);
      line-height: vw(18px);
    }
  }

  .limit_counter {
    font-size: vw(16px);
    line-height: vw(18px);
  }

  .err_text {
    font-size: vw(14px);
    line-height: vw(16px);
    margin-top: vw(4px);
  }
}
