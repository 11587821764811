@import "./styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

#root {
  display: flex;
  justify-content: center;
  position: relative;
}

.app {
  width: 100%;
}

@media screen and (min-width: 1641px) {
  .app {
    width: 1640px;
  }
}

.modal-dialog {
  .modal-header {
    padding: 24px 24px 0px 24px;
    border: 0;

    .btn-close {
      color: $grey;
    }
  }

  .modal-content {
    background-color: $blu1;
    border-radius: 20px;
  }

  .modal-body {
    padding: 0px 40px;
    display: flex;
    flex-direction: column;

    .title {
      //styleName: Greenwich/BOLD 32;
      font-family: Greenwich;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;
      color: $text_accent;
    }
  }

  .modal-footer {
    border: 0;
    padding: 20px;
  }
}

.input_group {
  display: flex;
  flex-direction: column;

  label {
    //styleName: Greenwich/Reg 18;
    font-family: Greenwich;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $text_accent;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  input {
    height: 60px;
    font-size: 24px;
    font-weight: 400;
    padding: 15px;
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    box-shadow: inset 0 0 0 50px #fff;

    &:hover {
      outline: 1px solid $blu3;
    }

    &:focus {
      outline: 2px solid $blu3;
    }

    &::placeholder {
      //styleName: Greenwich/Reg 16;
      font-family: Greenwich;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: $grey;
    }
  }
}

@media screen and (max-width: 1439px) {
  .app {
    width: 99.939vw;
  }

  .btn {
    height: 4.306vw;
    border-radius: 0.694vw;
    font-size: 2.222vw;
    line-height: 2.847vw;

    &.btn-primary {
      font-size: 2.222vw;
      line-height: 2.847vw;
    }

    &.btn-secondary {
      font-size: 2.5vw;
      line-height: 3.194vw;
    }

    &.btn-outline-primary {
      font-size: 2.222vw;
      line-height: 2.847vw;
    }
  }

  .modal-dialog {
    .modal-header {
      padding: 2.083vw;
    }

    .modal-content {
      border-radius: 1.389vw;
    }

    .modal-body {
      padding: 1.389vw 3.333vw;

      .title {
        font-size: 2.222vw;
        line-height: 2.847vw;
      }
    }

    .modal-footer {
      padding: 1.389vw;
    }
  }

  .input_group {
    label {
      font-size: 1.25vw;
      line-height: 1.458vw;
      margin-bottom: 0.556vw;
    }

    input {
      height: 60px;
      font-size: 1.667vw;
      padding: 1.042vw;
      border-radius: 0.556vw;
      -webkit-box-shadow: inset 0 0 0 3.472vw #fff;
      box-shadow: inset 0 0 0 3.472vw #fff;
    }
  }
}

.containerClasses {
  border-radius: 20px;
  margin-bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.contentClasses {
  font-family: Greenwich;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: $white;
}

.req_sign {
  font-family: "Greenwich";
  font-style: normal;
  font-weight: 400;
  color: $grey2;
  font-size: 18px;
}

@media screen and (max-width: 480px) {
  .modal-dialog {
    .modal-content {
      border-radius: vw(20px);
    }

    .modal-body {
      padding: 1.389vw 3.333vw;
    }
  }

  .btn {
    height: vw(62px);
    border-radius: vw(10px);
    font-size: vw(26px);
    line-height: vw(32px);

    &.btn-primary {
      font-size: vw(26px);
      line-height: vw(32px);
    }

    &.btn-secondary {
      font-size: vw(26px);
      line-height: vw(32px);
    }

    &.btn-outline-primary {
      font-size: vw(26px);
      line-height: vw(32px);
    }
  }
}
