@import "../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.main_wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: $white;
  border-radius: 8px;
  width: 337px;
  height: 60px;
  box-sizing: border-box;
  padding-left: 32px;
  padding-top: 20px;
  padding-bottom: 19px;
}

.time_wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.time_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $grey2;
}

@media screen and (max-width: 480px) {
  .main_wrapper {
    gap: vw(24px);
    border-radius: vw(8px);
    width: 100%;
    height: vw(60px);
    padding-left: vw(32px);
    padding-top: vw(20px);
    padding-bottom: vw(19px);
  }

  .time_wrapper {
    gap: vw(16px);
  }

  .time_text {
    font-size: vw(18px);
    line-height: vw(21px);
  }
}
