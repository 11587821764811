@import "../../styles/global-variables.scss";

.notification_wrapper {
  display: flex;
  flex-direction: column;
  width: 600px;

  .body {
    background-color: $blu1;
    padding: 40px;

    .welcome_text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $text_accent;
      margin-bottom: 70px;
    }

    .with_love {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $text_accent;
    }
  }
}
