@import "./../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.vv_button {
  &.btn {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    height: 62px;
    border-radius: 10px;
    //styleName: Greenwich/BOLD 32;
    font-family: Greenwich;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;

    &.btn-primary {
      border: none;
      text-decoration: none;
      color: $white;
      background-color: $pink4;
      //styleName: Greenwich/BOLD 32;
      font-family: Greenwich;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: center;

      &:hover {
        background-color: $pink3;
      }

      &:active {
        background-color: $pink5;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.btn-secondary {
      border: none;
      text-decoration: none;
      text-transform: none;
      background-color: $blu4;
      color: $white;
      //styleName: Greenwich/SemiBold 36;
      font-family: Greenwich SemBd;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px;
      letter-spacing: 0em;
      text-align: center;

      &:hover {
        background-color: $blu3;
      }

      &:active {
        background-color: $blu4;
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.btn-outline-primary {
      border: 2px solid $pink4;
      color: $pink4;
      //styleName: Greenwich/BOLD 32;
      font-family: Greenwich;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0em;
      text-align: center;

      &:hover {
        background-color: transparent;
        color: $pink3;
        border: 2px solid $pink3;
      }

      &:active {
        background-color: transparent;
        color: $pink5;
        border: 2px solid $pink5;
      }

      &:focus {
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}

// TODO: Temporary global style for migration
.btn {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  height: 62px;
  border-radius: 10px;
  //styleName: Greenwich/BOLD 32;
  font-family: Greenwich;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;

  &.btn-primary {
    border: none;
    text-decoration: none;
    color: $white;
    background-color: $pink4;
    //styleName: Greenwich/BOLD 32;
    font-family: Greenwich;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;

    &:hover {
      background-color: $pink3;
    }

    &:active {
      background-color: $pink5;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-secondary {
    border: none;
    text-decoration: none;
    text-transform: none;
    background-color: $blu2;
    color: $white;
    //styleName: Greenwich/SemiBold 36;
    font-family: Greenwich SemBd;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: center;

    &:hover {
      background-color: $blu3;
    }

    &:active {
      background-color: $blu4;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-outline-primary {
    border: 2px solid $pink4;
    color: $pink4;
    //styleName: Greenwich/BOLD 32;
    font-family: Greenwich;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;

    &:hover {
      background-color: transparent;
      color: $pink3;
      border: 2px solid $pink3;
    }

    &:active {
      background-color: transparent;
      color: $pink5;
      border: 2px solid $pink5;
    }

    &:focus {
      box-shadow: none;
      background-color: transparent;
    }
  }
}
