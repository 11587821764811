@import "../../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.upload_wrapper {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .upload_title {
    font-family: Greenwich;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #121212;
  }

  button {
    width: 100%;
    height: 62px;
    font-family: Greenwich;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $pink4;
    border: 2px solid $pink4;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
}

.camera_wrapper {
  background-color: $white;
  width: 200px;
  height: 200px;
  border-radius: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
}

.upload_label {
  width: 100%;
  border: none;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: $pink4;
  color: $white;
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 24px;
  border-radius: 10px;
  text-transform: uppercase;

  input {
    display: none;
  }
}

.err_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-top: 16px;
}

@media screen and (max-width: 480px) {
  .upload_wrapper {
    width: 100%;
    flex-direction: column;
    gap: vw(16px);

    .upload_title {
      font-size: vw(18px);
      line-height: vw(21px);
    }

    button {
      width: 100%;
      height: vw(62px);
      font-size: vw(18px);
      line-height: 100%;
      border: vw(2px) solid $pink4;
      border-radius: vw(10px);
    }
  }

  .camera_wrapper {
    background-color: $white;
    width: vw(440px);
    height: vw(440px);
    border-radius: vw(20px);

    img {
      border-radius: vw(20px);
    }
  }

  .upload_label {
    width: 100%;
    height: vw(62px);
    font-size: vw(18px);
    line-height: 100%;
    border: vw(2px) solid $pink4;
    border-radius: vw(10px);
  }
}
