@import "../../../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.wrapper {
  padding: 60px 0 60px;
  background-color: $blu1;
  border-radius: 20px;
}

.title {
  font-family: Greenwich SemBd;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  color: $text_accent;
}

.divider {
  background-color: $pink4;
  opacity: 0.3;
  height: 3px;
  margin-top: 12px;
  margin-bottom: 8px;
}

.title_description {
  font-family: Greenwich ExtLt;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: $text_accent;
  margin-bottom: 44px;
}

.slider_wrapper {
  padding-left: 50px;
  padding-right: 50px;
}

.slider {
  display: flex;
  gap: 48px;
  padding-left: 55px;
  padding-right: 65px;

  .slider_id {
    font-family: Greenwich SemBd;
    font-style: normal;
    font-weight: 600;
    font-size: 270px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $pink4;
  }

  .title {
    font-family: Greenwich;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 61px;
    text-align: left;
    text-transform: uppercase;
    color: $text_accent;
    margin-bottom: 16px;
  }

  .textFirst {
    font-family: "Greenwich";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    color: $text_accent;
    margin-bottom: 24px;
  }

  .textSecond {
    display: flex;
    gap: 36px;
    align-items: flex-end;

    div {
      font-family: "Greenwich";
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 23px;
      text-align: left;
      width: 475px;
      color: $text_accent;
    }

    button {
      width: 336px;
    }
  }
}

@media screen and (max-width: 1439px) {
  .wrapper {
    padding: vw(60px, 1440px) 0 vw(60px, 1440px);
  }

  .title {
    font-size: vw(44px, 1440px);
    line-height: vw(56px, 1440px);
  }

  .divider {
    margin-top: vw(12px, 1440px);
    margin-bottom: vw(8px, 1440px);
  }

  .title_description {
    font-size: vw(26px, 1440px);
    line-height: vw(33px, 1440px);
    margin-bottom: vw(44px, 1440px);
  }

  .slider_wrapper {
    padding-left: vw(50px, 1440px);
    padding-right: vw(50px, 1440px);
  }

  .slider {
    gap: vw(48px, 1440px);
    padding-left: vw(55px, 1440px);
    padding-right: vw(65px, 1440px);

    .slider_id {
      font-size: vw(270px, 1440px);
    }

    .title {
      font-size: vw(48px, 1440px);
      line-height: vw(61px, 1440px);
      margin-bottom: vw(16px, 1440px);
    }

    .textFirst {
      font-size: vw(24px, 1440px);
      line-height: vw(28px, 1440px);
      margin-bottom: vw(24px, 1440px);
    }

    .textSecond {
      gap: vw(36px, 1440px);

      div {
        font-size: vw(20px, 1440px);
        line-height: vw(23px, 1440px);
        width: vw(475px, 1440px);
      }

      button {
        width: vw(336px, 1440px);
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: vw(60px) 0 vw(60px);
    border-radius: vw(20px);
  }

  .title {
    font-weight: 400;
    font-size: vw(30px);
    line-height: vw(38px);
  }

  .divider {
    height: vw(3px);
    margin-top: vw(12px);
    margin-bottom: vw(8px);
  }

  .title_description {
    font-size: vw(18px);
    line-height: vw(20px);
    margin-bottom: vw(40px);
  }

  .slider_wrapper {
    margin: vw(-14px);
    width: calc(100% + vw(28px));
  }

  .slider {
    box-sizing: border-box;
    display: flex;
    gap: 0;
    padding: 0 vw(12px);

    .slider_id {
      position: absolute;
      font-weight: 400;
      font-size: vw(180px);
      line-height: vw(180px);
    }

    .title {
      font-size: vw(22px);
      line-height: vw(28px);
      margin-bottom: vw(16px);
      margin-left: vw(130px);
    }

    .textFirst {
      font-size: vw(18px);
      line-height: vw(21px);
      margin-bottom: vw(8px);
      margin-left: vw(130px);
      height: vw(150px);
    }

    .textSecond {
      display: flex;
      flex-direction: column;
      gap: vw(40px);

      div {
        font-size: vw(14px);
        line-height: vw(16px);
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }
}
