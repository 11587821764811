@import "../../../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.forgot_password_modal {
  .modal-dialog {
    max-width: 596px;

    .modal-content {
      background-color: $blu1;
      border-radius: 20px;
    }

    .modal-body {
      padding: 0px 40px;
      display: flex;
      flex-direction: column;

      .title {
        //styleName: Greenwich/BOLD 32;
        font-family: Greenwich;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 33px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: $text_accent;
        margin-bottom: 8px;
      }

      .description {
        //styleName: Greenwich/Reg 18;
        font-family: Greenwich;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: $text_accent;
        margin-bottom: 24px;
      }

      .buttons {
        display: flex;
        justify-content: space-between;
      }

      .btn {
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
        height: 62px;
        width: 340px;
        border-radius: 10px;
        //styleName: Greenwich/BOLD 32;
        font-family: Greenwich;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: center;

        &.btn-primary {
          border: none;
          text-decoration: none;
          color: $white;
          background-color: $pink4;
        }

        &.btn-outline-primary {
          border: 2px solid $pink4;
          color: $pink4;
        }
      }

      .input_group {
        display: flex;
        flex-direction: column;
        margin-bottom: 84px;

        label {
          //styleName: Greenwich/Reg 18;
          font-family: Greenwich;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          color: $text_accent;
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
        }

        input {
          height: 60px;
          font-size: 24px;
          font-weight: 400;
          padding: 15px;
          border: 0;
          border-radius: 8px;

          &:hover {
            border: 1px solid $text_accent;
          }

          &:focus {
            outline: none;
            border: 2px solid $text_accent;
          }
        }
      }
    }

    .modal-footer {
      border: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .forgot_password_modal {
    .modal-dialog {
      .modal-body {
        padding: 0 vw(20px);

        .title {
          padding-top: vw(24px);
          font-size: vw(18px);
          line-height: 100%;
          width: vw(300px);
        }

        .description {
          font-size: vw(12px);
          line-height: 1;
        }

        .input_group {
          margin-bottom: vw(52px);

          label {
            font-size: vw(18px);
            line-height: vw(21px);
            margin-bottom: vw(8px);
          }

          input {
            height: vw(60px);
            font-size: vw(24px);
            padding: vw(15px);
            border-radius: vw(8px);

            &:hover {
              border: vw(1px) solid $text_accent;
            }

            &:focus {
              outline: none;
              border: vw(2px) solid $text_accent;
            }
          }
        }
      }

      .modal-header {
        padding-top: 0;
      }

      .modal-body .title {
        font-size: vw(18px);
        line-height: 100%;
        padding-top: vw(24px);
      }

      .modal-body .btn.btn-primary {
        font-size: vw(26px);
        line-height: vw(33px);
        height: vw(52px);
      }

      .modal-footer {
        padding-bottom: vw(32px);
      }
    }
  }
}
