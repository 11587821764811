@import "./styles/global-variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "./assets/fonts/Greenwich/stylesheet.css";
@import "./assets/fonts/Greenwich/stylesheet.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  //overflow-y: overlay;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Greenwich", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Greenwich", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text_accent;
  overflow-x: hidden;
  //position: relative;
  //padding-right: 0 !important;
  //overflow-y: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}
