@import "../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.notificaton_message{
  width: 100%;
  display: inline-block;
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  background: rgb(235, 24, 100);
  color: #ffffff;
  text-align: center;
  padding: 4px 12px;

  h6 {
    color: #ffffff;
  }
}

.vv_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 0 80px;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    background: linear-gradient(90.4deg, #382c5d 6.97%, #282582 95.29%),
      linear-gradient(0deg, #382c5d, #382c5d);
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }

  .nav_wrapper {
    display: flex;
    gap: 80px;

    nav {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        align-items: center;
        gap: 80px;
        margin: 0;

        .burger_menu,
        .log_out {
          .account_icon {
            color: $pink2;
          }

          .nav_dropdown {
            .list_wrapper {
              display: flex;
              flex-direction: column;

              .dropdown-item {
                padding: 10px 15px;

                a {
                  font-family: Greenwich;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 31px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: $purple1;

                  button {
                    font-family: Greenwich;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 31px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: $purple1;
                  }
                }

                &:active {
                  background-color: transparent;
                }

                &:hover {
                  background-color: transparent;
                }
              }
            }

            .dropdown-menu {
              background-color: $blu1;
              border-radius: 8px;
            }

            .dropdown-toggle {
              padding: 10px 0px;

              &::after {
                display: none;
              }
            }
          }
        }

        .log_in {
          display: flex;
          gap: 10px;
          color: $pink2;

          .account_icon {
            color: $blu2;
          }

          a {
            cursor: pointer;
            font-family: Greenwich;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 31px;
            letter-spacing: 0em;
            text-align: center;
            text-transform: unset;
            color: $blu2;

            button {
              background-color: transparent;
              border: 0;
              font-family: Greenwich;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 31px;
              letter-spacing: 0em;
              text-align: center;
              text-transform: unset;
              color: $blu2;
            }
          }
        }
      }
    }

    li {
      font-family: Greenwich;
      font-size: 24px;
      font-weight: 600;
      line-height: 31px;
      color: $blu2;

      a {
        font-family: Greenwich;
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        color: $blu2;

        button {
          font-family: Greenwich;
          font-size: 24px;
          font-weight: 600;
          line-height: 31px;
          color: $purple1;
        }

        &.active button {
          color: $pink2;
        }
      }
    }

    //.vv_language_selector {
    //  display: flex;
    //  align-items: center;
    //
    //  .languages_list {
    //    display: flex;
    //    gap: 10px;
    //    margin: 0;
    //
    //    .list_item {
    //      padding: 0 5px;
    //      border-radius: 5px;
    //
    //      &.active {
    //        background-color: #79a7ec;
    //        color: #382c5d;
    //      }
    //    }
    //  }
    //}
  }
}

@media screen and (max-width: 1439px) {
  .vv_header {
    padding: 0 40px;

    .nav_wrapper {
      gap: 40px;

      nav {
        ul {
          gap: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .vv_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: vw(70px);
    padding: 0 vw(20px);

    .logo_wrapper a img {
      height: vw(70px);
    }

    .nav_wrapper {
      gap: vw(24px);

      nav {
        ul {
          gap: vw(24px);

          .burger_menu,
          .log_out {
            .nav_dropdown {
              .list_wrapper {
                .dropdown-item {
                  padding: vw(15px) vw(20px);

                  a {
                    font-size: vw(20px);
                    line-height: vw(30px);

                    button {
                      font-size: vw(20px);
                      line-height: vw(30px);
                    }
                  }
                }
              }

              .dropdown-menu {
                min-width: vw(216px);
                border-radius: vw(8px);
              }

              .dropdown-toggle {
                padding: vw(10px) 0;
              }
            }
          }

          .log_in {
            gap: vw(10px);

            a {
              font-size: vw(20px);
              line-height: vw(20px);

              button {
                font-size: vw(20px);
                line-height: vw(20px);
              }
            }
          }
        }
      }

      li {
        font-size: vw(20px);
        line-height: vw(30px);

        a {
          font-size: vw(20px);
          line-height: vw(30px);

          button {
            font-size: vw(20px);
            line-height: vw(30px);
          }

          &.active button {
            color: $pink2;
          }
        }
      }
    }
  }
}

.vv_header
  .nav_wrapper
  nav
  ul
  .burger_menu
  .nav_dropdown
  .list_wrapper
  .dropdown-item
  a
  button,
.vv_header
  .nav_wrapper
  nav
  ul
  .log_out
  .nav_dropdown
  .list_wrapper
  .dropdown-item
  a
  button {
  padding: 0;
}
