@import "../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.vv_profile_page {
  position: relative;

  .title {
    //styleName: Greenwich/SEMIBOLD 44;
    font-family: Greenwich SemBd;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: center;
    color: $text_accent;
    text-transform: uppercase;
    padding: 10px 0px;
  }

  .vv_divider {
    .divider {
      width: 100%;
      border-bottom: 3px solid $blu4;
      max-width: 100%;
    }

    margin-bottom: 60px;
  }

  .flag_icon {
    width: 24px;
    height: 24px;
  }

  .background {
    position: absolute;
    height: 170px;
    background-color: #e6f0ff;
    z-index: -1;
    left: 50%;
    margin-left: -50vw;
    top: 0;
    width: 100vw;

    &.tall {
      height: 282px;
    }
  }

  .user_header_info_block {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    padding: 40px 80px 100px 80px;

    .profile_avatar_wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 432px;

      .profile_name {
        display: none;
      }

      .location {
        display: none;
      }

      .profile_avatar_image {
        border-radius: 20px;
        width: 432px;
        height: 432px;
        object-fit: cover;
      }

      .public_phone_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blu1;
        height: 78px;
        gap: 20px;
        border-radius: 4px;

        .number {
          //styleName: Greenwich/SemiBold 28;
          font-family: Greenwich SemBd;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 39px;
          letter-spacing: 0em;
          text-align: center;
          color: $text_accent;
          cursor: auto;
        }
      }

      .rating_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .rating {
          display: flex;
          align-items: center;
          gap: 10px;

          .count {
            //styleName: Greenwich/Reg 24;
            font-family: Greenwich;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: center;
          }
        }

        .review {
          //styleName: Greenwich/Reg 24;
          font-family: Greenwich;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: center;
        }
      }

      .feedback {
        font-family: Greenwich;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
        color: $pink4;
        text-decoration: underline;
      }

      .verified_text {
        //styleName: Greenwich/Light 20;
        font-family: Greenwich;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
      }

      .vibe_text {
        //styleName: Greenwich/SemiBold 28;
        font-family: Greenwich SemBd;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .profile_info_wrapper {
      width: 70%;

      .header_info_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .location {
          //styleName: Greenwich/Light 24;
          font-family: Greenwich;
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 28px;
          letter-spacing: 0em;
          display: flex;
          align-items: center;
          gap: 10px;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      a {
        font-family: Greenwich;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: $pink4;
        text-decoration: underline;
        display: flex;
        align-items: center;

        .arrow_right_icon {
          width: 25px;
        }
      }

      .profile_name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        //styleName: Greenwich/Med 54;
        font-family: Greenwich Med;
        font-size: 54px;
        font-style: normal;
        font-weight: 400;
        line-height: 69px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 50px;
      }

      .skills_wrapper {
        display: flex;
        justify-content: space-between;
        //styleName: Greenwich/SemiBold 28;
        font-family: Greenwich SemBd;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 39px;
        letter-spacing: 0em;
        margin-bottom: 20px;

        .languages {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .profile_about_description {
        font-family: Greenwich;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 36px;
      }

      .vv_divider {
        .divider {
          border-bottom: 3px solid $pink4;
        }
      }

      .services_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
        flex-wrap: wrap;
        margin-bottom: 50px;

        .card_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;

          .svg_card {
            width: 84px;
          }
        }
      }

      .bio_lists {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        .bio_item {
          background-color: $blu1;
          padding: 8px 12px;
          border-radius: 4px;
          display: inline-flex;
          align-items: center;
          gap: 8px;
          //styleName: Greenwich/Reg 20;
          font-family: Greenwich;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: center;
        }
      }
    }
  }

  .my_work_block {
    padding: 0px 80px 100px 80px;

    .vv_divider {
      .divider {
        width: 100%;
        border-bottom: 3px solid $blu4;
      }

      margin-bottom: 60px;
    }

    .cards_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .vv_card {
        border-radius: 20px;
        width: 390px;

        .image_wrapper {
          display: flex;
          justify-content: center;

          .vv_card_img {
            border-radius: 20px;
            width: 390px;
            height: 390px;
            object-fit: cover;
          }
        }
      }
    }
  }

  .my_bio_block {
    padding: 0px 80px 100px 80px;

    .text {
      //styleName: Greenwich/SemiBold 28;
      font-family: Greenwich SemBd;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 39px;
      letter-spacing: 0em;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      margin-bottom: 8px;
      margin-top: -50px;
    }

    .bio_lists {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 50px;

      .bio_item {
        background-color: $blu1;
        padding: 8px 12px;
        border-radius: 4px;
        gap: 8px;
        //styleName: Greenwich/Reg 20;
        font-family: Greenwich;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;

        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $blu4;
        }
      }
    }
  }

  .my_interests_block {
    padding: 0px 80px 100px 80px;

    .my_interests_wrapper {
      background-color: $blu1;
      border-radius: 20px;

      .title {
        padding-top: 60px;
      }

      .vv_divider {
        .divider {
          border-bottom: 3px solid $white;
          opacity: 1;
        }
      }

      .my_interests_lists {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 50px;
        padding: 0px 50px 60px 50px;

        .my_interests_item {
          display: inline-flex;
          align-items: center;
          gap: 8px;
          //styleName: Greenwich/Reg 20;
          font-family: Greenwich;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: center;
          background-color: $white;
          padding: 8px 10px;
          border-radius: 4px;
        }
      }
    }
  }

  .my_certificates_block {
    padding: 0px 80px 100px 80px;

    .vv_divider {
      .divider {
        width: 100%;
        border-bottom: 3px solid $blu4;
      }

      margin-bottom: 60px;
    }

    .cards_wrapper {
      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: center;

      .vv_card {
        padding: 40px 20px;
        background-color: $blu1;
        border-radius: 12px;
        width: 300px;

        .image_wrapper {
          display: flex;
          justify-content: center;
          margin-bottom: 16px;

          img {
            width: 200px;
            height: 136px;
            object-fit: cover;
          }
        }

        &__title {
          //styleName: Greenwich/Bold 20;
          font-family: Greenwich;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 23px;
          letter-spacing: 0em;
          text-align: left;
          color: $text_accent;
        }
      }
    }
  }

  .my_contact_block {
    display: none;
  }

  .my_working_salons_block {
    padding: 100px 80px 100px 80px;

    .vv_divider {
      .divider {
        width: 100%;
        border-bottom: 3px solid $blu4;
      }

      margin-bottom: 60px;
    }

    .cards_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .vv_card {
        padding: 0px 20px 24px 20px;
        background-color: $blu1;
        border-radius: 20px;
        width: 300px;

        .image_wrapper {
          display: flex;
          justify-content: center;
          margin-bottom: 16px;

          img {
            border-radius: 20px 20px 0px 0px;
          }
        }

        &__title {
          //styleName: Greenwich/SemiBold 28;
          font-family: Greenwich SemBd;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 39px;
          letter-spacing: 0em;
          text-align: left;
          color: $text_accent;
          margin-bottom: 16px;
        }

        &__location_description {
          //styleName: Greenwich/Reg 18;
          font-family: Greenwich Regular;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          color: $text_accent;
          display: flex;
          gap: 10px;
        }

        .rating_wrapper {
          display: flex;
          gap: 10px;
          margin-bottom: 64px;

          .rating {
            display: flex;
          }

          .review {
            //styleName: Greenwich/Reg 20;
            font-family: Greenwich;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: $text_accent;
          }
        }
      }
    }
  }

  .contact_me_block {
    display: flex;
    justify-content: center;
    padding: 0px 80px 100px 80px;

    .btn {
      padding: 0px 100px;
    }

    button {
      width: 432px;
    }
  }
}

@media screen and (max-width: 1439px) {
  .vv_profile_page {
    .title {
      font-size: 3.056vw;
      font-style: normal;
      font-weight: 600;
      line-height: 3.889vw;
      padding: 0.694vw 0;
    }

    .vv_divider {
      margin-bottom: 4.167vw;
    }

    .flag_icon {
      width: 1.667vw;
      height: 1.667vw;
    }

    .user_header_info_block {
      gap: 3.472vw;
      padding: 2.778vw 5.556vw 6.944vw 5.556vw;

      .profile_avatar_wrapper {
        gap: 1.667vw;
        width: 30vw;

        .profile_avatar_image {
          border-radius: 1.389vw;
          width: 30vw;
          height: 30vw;
        }

        .rating_wrapper {
          .rating {
            gap: 0.694vw;

            .count {
              font-size: 1.667vw;
              line-height: 1.944vw;
            }
          }

          .review {
            font-size: 1.667vw;
            line-height: 1.944vw;
          }
        }

        .feedback {
          font-size: 1.25vw;
          line-height: 1.458vw;
        }

        .verified_text {
          font-size: 1.389vw;
          line-height: 1.597vw;
        }

        .vibe_text {
          font-size: 1.944vw;
          line-height: 2.708vw;
          gap: 0.694vw;
        }
      }

      .profile_info_wrapper {
        .header_info_wrapper {
          .location {
            font-size: 1.667vw;
            line-height: 1.944vw;
            gap: 0.694vw;
          }
        }

        a {
          font-size: 1.25vw;
          line-height: 1.458vw;

          .arrow_right_icon {
            width: 1.736vw;
          }
        }

        .profile_name {
          font-size: 3.75vw;
          line-height: 4.792vw;
          margin-bottom: 3.472vw;
        }

        .skills_wrapper {
          font-size: 1.944vw;
          line-height: 2.708vw;
          margin-bottom: 1.389vw;

          .languages {
            gap: 0.694vw;
          }
        }

        .profile_about_description {
          font-size: 1.667vw;
          line-height: 2.014vw;
          margin-bottom: 2.5vw;
        }

        .services_list {
          margin-bottom: 3.472vw;

          .card_wrapper {
            gap: 1.042vw;

            .svg_card {
              width: 5.833vw;
            }
          }
        }

        .bio_lists {
          gap: vw(20px, 1440px);

          .bio_item {
            gap: 0.556vw;
            font-size: 1.389vw;
            line-height: 1.597vw;

            .dot {
              width: 0.556vw;
              height: 0.556vw;
            }
          }
        }
      }
    }

    .my_work_block {
      padding: 0 5.556vw 6.944vw 5.556vw;

      .vv_divider {
        margin-bottom: 4.167vw;
      }

      .cards_wrapper {
        .vv_card {
          border-radius: 1.389vw;
          width: 27.083vw;

          .image_wrapper {
            .vv_card_img {
              width: 27.083vw;
              height: 27.083vw;
            }
          }
        }
      }
    }

    .certificate_card {
      width: 13.889vw;
    }

    .salon_card_img {
      width: 20.833vw;
    }

    .my_bio_block {
      padding: 0 5.556vw 6.944vw 5.556vw;

      .text {
        justify-content: flex-end;
        font-size: 1.944vw;
        line-height: 2.708vw;
        gap: 1.111vw;
        margin-bottom: 0.556vw;
      }

      .bio_lists {
        gap: 3.472vw;

        .bio_item {
          gap: 0.556vw;
          font-size: 1.389vw;
          line-height: 1.597vw;

          .dot {
            width: 0.556vw;
            height: 0.556vw;
          }
        }
      }
    }

    .my_interests_block {
      padding: 0 5.556vw 6.944vw 5.556vw;

      .my_interests_wrapper {
        border-radius: 1.389vw;

        .title {
          padding-top: 4.167vw;
        }

        .my_interests_lists {
          gap: 3.472vw;
          padding: 0 3.472vw 4.167vw 3.472vw;

          .my_interests_item {
            gap: 0.556vw;
            font-size: 1.389vw;
            line-height: 1.597vw;
            padding: 0.556vw 0.694vw;
            border-radius: 0.278vw;
          }
        }
      }
    }

    .my_certificates_block {
      padding: 0 5.556vw 6.944vw 5.556vw;

      .vv_divider {
        margin-bottom: 4.167vw;
      }

      .cards_wrapper {
        .vv_card {
          padding: 2.778vw 1.389vw;
          border-radius: 0.833vw;
          width: 20.833vw;

          .image_wrapper {
            margin-bottom: 1.111vw;

            img {
              width: 13.889vw;
              height: 9.444vw;
            }
          }

          &__title {
            font-size: 1.389vw;
            line-height: 1.597vw;
            margin-bottom: 0.556vw;
          }

          &__description {
            font-size: 1.25vw;
            line-height: 1.458vw;
          }
        }
      }
    }

    .my_working_salons_block {
      padding: 6.944vw 5.556vw;

      .vv_divider {
        margin-bottom: 4.167vw;
      }

      .cards_wrapper {
        .vv_card {
          padding: 0 1.389vw 1.667vw 1.389vw;
          background-color: $blu1;
          border-radius: 1.389vw;
          width: 20.833vw;

          .image_wrapper {
            margin-bottom: 1.111vw;

            img {
              border-radius: 1.389vw 1.389vw 0 0;
            }
          }

          &__title {
            font-size: 1.944vw;
            line-height: 2.708vw;
            margin-bottom: 1.111vw;
          }

          &__location_description {
            font-size: 1.25vw;
            line-height: 1.458vw;
            gap: 0.694vw;
          }

          .rating_wrapper {
            gap: 0.694vw;
            margin-bottom: 4.444vw;

            .review {
              font-size: 1.389vw;
              line-height: 1.597vw;
            }
          }
        }
      }
    }

    .contact_me_block {
      display: flex;
      justify-content: center;
      padding: 0 5.556vw 6.944vw 5.556vw;

      .btn {
        padding: 0 6.944vw;
      }

      button {
        width: 35%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .vv_profile_page {
    .user_header_info_block {
      flex-direction: column;

      padding: vw(16px) vw(20px);

      .profile_avatar_wrapper {
        width: 100%;
        gap: vw(16px);

        .location {
          order: 0;
          display: flex;
          align-items: center;
          font-size: vw(18px);
          line-height: vw(21px);

          svg {
            width: vw(18px);
            height: vw(18px);
          }
        }

        .profile_name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: vw(38px);
          line-height: 1;
        }

        .profile_avatar_image {
          order: 2;
          width: 100%;
          height: vw(440px);
          border-radius: vw(20px);
        }

        .public_phone_wrapper {
          order: 5;
          height: vw(62px);
          border-radius: vw(10px);
          margin: vw(20px) 0 vw(32px);

          .img {
            width: vw(26px);
            height: vw(26px);
          }

          .number {
            font-size: vw(26px);
            line-height: vw(33px);
          }
        }

        .public_contact_wrapper {
          order: 5;
          margin: vw(20px) 0 vw(32px);

          button {
            width: 100%;
          }
        }

        .verified_text {
          order: 3;
          margin-top: vw(-8px);
          font-size: vw(14px);
          text-align: center;
        }

        .vibe_text {
          order: 4;
          font-size: vw(24px);
          line-height: vw(32px);
        }
      }

      .profile_info_wrapper {
        width: 100%;

        .header_info_wrapper {
          .location {
            display: none;
          }
        }

        .profile_name {
          display: none;
        }

        .skills_wrapper {
          font-size: vw(22px);
          line-height: vw(28px);

          .languages {
            margin-bottom: vw(24px);

            svg {
              height: vw(14px);
              width: vw(20px);
              margin-left: vw(14px);
            }
          }
        }

        .profile_about_description {
          margin-bottom: vw(32px);
          font-size: vw(18px);
          line-height: vw(21px);
        }

        .vv_divider {
          margin-bottom: vw(44px);
        }

        .services_list {
          .card_wrapper {
            div .svg_card {
              width: vw(60px);
              height: vw(60px);
            }
            .label {
              font-size: vw(16px);
              line-height: vw(19px);
            }
          }
        }

        .bio_lists {
          .bio_item {
            padding: vw(7.5px) vw(12px);
            font-size: vw(18px);
            line-height: vw(21px);
          }
        }
      }
    }

    .my_work_block {
      padding: vw(40px) vw(20px);

      .title {
        padding: 0;

        font-size: vw(30px);
        line-height: vw(38px);
      }

      .cards_wrapper {
        .vv_card {
          width: 100%;

          .image_wrapper {
            .vv_card_img {
              width: vw(440px);
              height: vw(440px);
            }
          }
        }
      }

      .vv_divider {
        margin-top: vw(12px);
        margin-bottom: vw(32px);
      }
    }

    .my_bio_block {
      padding: vw(20px);
      display: flex;
      flex-direction: column;

      .title {
        order: 0;
        padding: 0;

        font-size: vw(30px);
        line-height: vw(38px);
      }

      .vv_divider {
        order: 1;
        margin: vw(12px) 0;
      }

      .text {
        order: 2;
        display: flex;
        justify-content: flex-start;
        margin: 0;
        margin-bottom: vw(32px);
        font-size: vw(20px);
        line-height: vw(20px);

        .flag_icon {
          width: vw(20px);
          height: vw(14px);
        }
      }

      .bio_lists {
        order: 3;

        .bio_item {
          padding: vw(7.5px) vw(12px);
          font-size: vw(18px);
          line-height: vw(21px);
        }
      }
    }

    .my_interests_block {
      width: 100%;
      padding: vw(40px) vw(20px);

      .my_interests_wrapper {
        height: auto;
        border-radius: vw(20px);

        .title {
          padding-top: vw(60px);
          padding-bottom: 0;
          font-size: vw(30px);
          line-height: vw(38px);
        }

        .vv_divider {
          margin-top: vw(12px);
          margin-bottom: vw(32px);
        }

        .my_interests_lists {
          .my_interests_item {
            padding: vw(7.5px) vw(12px);
            border-radius: vw(4px);
            font-size: vw(18px);
            line-height: vw(21px);
          }
        }
      }
    }

    .my_certificates_block {
      padding: vw(20px);

      .title {
        padding: 0;

        font-size: vw(30px);
        line-height: vw(38px);
      }

      .cards_wrapper {
        gap: 30px;

        .vv_card {
          padding: vw(40px) vw(20px);
          background-color: $blu1;
          width: vw(440px);
          height: vw(344px);

          .image_wrapper {
            margin-bottom: vw(16px);

            img {
              width: vw(320px);
              height: vw(216px);
            }
          }

          &__title {
            font-size: vw(20px);
            line-height: vw(23px);
            letter-spacing: 0em;
          }
        }
      }

      .vv_divider {
        margin-top: vw(12px);
        margin-bottom: vw(32px);
      }
    }

    .contact_me_block {
      padding: vw(40px) vw(20px) vw(80px);

      button {
        width: 100%;
      }
    }
  }
}
