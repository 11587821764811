@import "/src/mixins/mixins.scss";

.main_wrapper {
  width: 180px;
  height: 60px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 4px;
}

.item_wrapper {
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  border-radius: 8px;
  width: 86px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

@media screen and (max-width: 480px) {
  .main_wrapper {
    width: 100%;
    height: vw(60px);
    border-radius: vw(8px);
    padding-left: vw(4px);
    padding-right: vw(4px);
  }

  .item_wrapper {
    font-size: vw(20px);
    line-height: vw(23px);
    border-radius: vw(8px);
    width: 50%;
    height: vw(52px);
  }
}
