@import "../../../../../styles/global-variables";

.custom_radio {
  accent-color: $pink4;
}

.radio_buttons_group_wrapper {
  display: flex;
  gap: 100px;

  label {
    //styleName: Greenwich/Reg 20;
    font-family: Greenwich;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: $text_accent;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
