@import "../../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.max_width_wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

.top_area_wrapper {
  background-color: $blu1;
  padding-top: 90px;
  padding-bottom: 60px;
}

.top_area_title {
  font-family: "Greenwich SemBd";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 56px;
  text-transform: uppercase;
  color: $text_accent;
  text-align: center;
}

.divider {
  height: 3px;
  width: 820px;
  background-color: $pink4;
  opacity: 0.4;
  margin: 12px auto 8px;
}

.line {
  width: 100%;
  border-bottom: 3px solid $blu1;
}

.page_size_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  margin-top: 60px;
  padding-right: 30px;
}

.page_number_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  margin: 60px 0;
  padding-right: 30px;
}

.page_number {
  //styleName: Greenwich/ Light 18;
  font-family: Greenwich;
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  color: $text_accent;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  min-width: 140px;
}

.top_area_sub_title {
  font-family: "Greenwich ExtLt";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: $text_accent;
}

.vibes_wrapper {
  display: flex;
  justify-content: center;
  gap: 28px;
  margin-top: 32px;
}

.vibe_item {
  display: flex;
  gap: 8px;
}

.vibe_item_text {
  font-family: "Greenwich";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: $text_accent;
}

.selects_wrapper {
  width: 540px;
  display: flex;
  gap: 12px;
  margin: 60px auto 0;
}

.catalog_wrapper {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.catalog_item_wrapper {
  cursor: pointer;
  background-color: $blu1;
  border-radius: 20px;
  width: 408px;
  height: 500px;

  .image_wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: 248px;
      object-fit: cover;
      border-radius: 20px 20px 0px 0px;
    }
  }
}

.catalog_item_text_area {
  margin-top: 26px;
  padding: 0 16px;
}

.catalog_item_name {
  font-family: "Greenwich SemBd";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  cursor: pointer;
}

.catalog_item_location {
  display: flex;
  gap: 8px;
  margin-top: 9.5px;

  p {
    font-family: "Greenwich";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: $text_accent;
    margin-bottom: 0;
  }
}

.catalog_item_vibe {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  img {
    width: 20px;
    height: 20px;
  }

  p {
    font-family: "Greenwich";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: $text_accent;
    margin-bottom: 0;
  }
}

.catalog_item_descriptions {
  font-family: "Greenwich";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-top: 24px;

  div {
    height: 42px;
  }

  p {
    margin-top: 4px;
  }
}

.see_more_button {
  width: 408px;
  margin: 71px auto 100px;
}

.catalog_flex_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no_results_found_wrapper {
  font-family: Greenwich;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

@media screen and (max-width: 480px) {
  .top_area_wrapper {
    padding: vw(60px) vw(20px);
  }

  .max_width_wrapper {
    width: 100%;
    overflow: hidden;
  }

  .top_area_title {
    font-size: vw(30px);
    line-height: vw(38px);
  }

  .divider {
    width: vw(388px);
    margin: vw(12px) auto vw(8px);
  }

  .top_area_sub_title {
    font-size: vw(18px);
    line-height: vw(20px);
  }

  .vibes_wrapper {
    display: none;
  }

  .selects_wrapper {
    width: vw(260px);
    gap: 0;
    margin-top: vw(48px);
    flex-direction: column;
  }

  .catalog_flex_wrapper {
    padding: vw(60px) vw(20px);
  }

  .catalog_wrapper {
    margin-top: 0;
    gap: vw(40px);
  }

  .catalog_item_wrapper {
    width: 100%;
    min-height: auto;
    height: vw(500px);
    border-radius: vw(20px);
    padding: 0;

    .image_wrapper {
      img {
        width: 100%;
        height: vw(248px);
      }
    }
  }

  .catalog_item_text_area {
    margin-top: 0;
    padding: vw(26px) vw(16px) 0;
  }

  .catalog_item_name {
    font-size: vw(26px);
    line-height: vw(34px);
  }

  .catalog_item_location {
    gap: 8px;
    margin-top: vw(10px);

    p {
      font-size: vw(18px);
      line-height: vw(21px);
    }

    svg {
      width: vw(14px);
    }
  }

  .catalog_item_vibe {
    gap: vw(8px);
    margin-top: vw(8px);

    p {
      font-size: vw(18px);
      line-height: vw(21px);
    }

    img {
      width: vw(20px);
      height: auto;
    }
  }

  .catalog_item_descriptions {
    margin-top: vw(24px);

    div {
      height: vw(42px);
      font-size: vw(18px);
      line-height: vw(21px);
    }

    p {
      margin-top: vw(4px);
    }
  }

  .see_more_button {
    width: 100%;
    margin-top: vw(60px);
    margin-bottom: 0;
  }

  .page_size_wrapper {
    gap: vw(30px);
    margin-top: vw(60px);
    padding-right: 0;
  }

  .line:first-child {
    display: none;
  }

  .page_number_wrapper {
    gap: vw(20px);
    margin: vw(20px) 0;
    padding-right: 0;
    justify-content: space-between;
  }

  .page_number {
    text-align: left;
    font-size: vw(18px);
    line-height: vw(21px);
    min-width: auto;
    flex-shrink: 0;
  }
}
