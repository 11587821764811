@import "../../../styles/global-variables.scss";

.notification_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 102px;
  background-color: $white;
  gap: 10px;

  .title {
    font-family: Verdana;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .social_links_wrapper {
    display: flex;
    gap: 10px;
  }

  .social_link {
    width: 38px;
    height: 38px;
  }
}
