@import "../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.page_title {
  //styleName: Greenwich/SemiBold 36;
  font-family: Greenwich SemBd;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}

.title {
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $blu4;
  margin-bottom: 30px;
  width: 80%;
}

.inputs_wrapper {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.form_wrapper {
  display: flex;
  flex-direction: column;
  gap: 94px;
}

.input_wrapper {
  display: flex;
  gap: 12px;
  width: 100%;
}

.textarea_group {
  label {
    //styleName: Greenwich/Reg 18;
    font-family: Greenwich;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $grey2;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  textarea {
    height: 146px;
    width: 100%;
    font-size: 24px;
    font-weight: 400;
    padding: 15px;
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    box-shadow: inset 0 0 0 50px #fff;
    resize: none;

    &:hover {
      outline: 1px solid $text_accent;
    }

    &:focus {
      outline: 1px solid $text_accent;
    }
  }
}

.btn_bottom_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  .cancel_btn {
    width: 260px;
  }
}

@media screen and (max-width: 480px) {
  .page_title {
    margin-bottom: vw(16px);
    font-size: vw(28px);
    line-height: vw(40px);
  }

  .title {
    width: 100%;
    margin-bottom: vw(31px);
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .inputs_wrapper {
    gap: vw(23px);

    .input_wrapper {
      flex-direction: column;
      gap: vw(23px);
    }
  }
}
