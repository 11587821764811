@import "/src/mixins/mixins.scss";

.ant-picker-ranges {
  display: none;
}

.ant-picker {
  border-color: transparent;
  background-color: transparent;
}

.ant-picker:hover {
  border-color: transparent;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-input input {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #121212;
}

.ant-picker-clear {
  display: none;
}

.ant-picker-disabled {
  background-color: transparent !important;
  border-color: transparent !important;
}

@media screen and (max-width: 480px) {
  .ant-picker-input input {
    font-size: vw(18px);
    line-height: vw(21px);
  }
}
