@import "../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.main_wrapper img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 480px) {
  .main_wrapper {
    img {
      width: vw(17px);
      height: vw(17px);
    }
  }
}

@media screen and (max-width: 1440px) {
  .main_wrapper img {
    width: vw(20px, 1440px);
    height: vw(20px, 1440px);
  }
}
