@import "../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.radio_group_wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 82px;
}

.text_wrapper {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: $text_accent;
}

.group_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .radio_group_wrapper {
    display: flex;
    justify-content: space-between;
    gap: vw(40px);
  }

  .text_wrapper {
    font-size: vw(20px);
    line-height: vw(23px);
  }

  .group_wrapper {
    gap: vw(8px);
  }
}
