@import "../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.req_sign {
  font-size: 18px;
  line-height: 21px;
  color: $grey2;
}

@media screen and (max-width: 480px) {
  .req_sign {
    font-size: vw(18px);
    line-height: vw(21px);
  }
}
