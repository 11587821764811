@import "../../../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.vv_card {
  padding: 0 27px;

  .card_photo {
    width: 100%;
    height: 248px;
    margin-bottom: 28px;
    object-fit: cover;
    border-radius: 20px;
  }

  &__name {
    //styleName: Greenwich/Light 24;
    font-family: Greenwich;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: $grey2;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 1439px) {
  .vv_artists_slider {
    .cards_wrapper {
      gap: 3.819vw;

      .vv_card {
        img {
          width: 100%;
          margin-bottom: 1.944vw;
        }

        &__name {
          //styleName: Greenwich/Light 24;
          font-family: Greenwich;
          font-size: 1.667vw;
          line-height: 1.944vw;
          margin-bottom: 12px;
        }

        &__title {
          //styleName: Greenwich/SemiBold 26;
          font-family: Greenwich SemBd;
          font-size: 1.806vw;
          line-height: 33px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .vv_card {
    padding: 0 27px;

    .card_photo {
      width: 100%;
      height: vw(268px);
      margin-bottom: vw(20px);
      border-radius: vw(20px);
    }

    .vv_card__name {
      font-weight: 300;
      font-size: vw(24px);
      line-height: vw(28px);
    }
  }
}
