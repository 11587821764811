@import "../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.page_title {
  //styleName: Greenwich/SemiBold 36;
  font-family: Greenwich SemBd;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}

.checkboxes_wrapper {
  padding-left: 40px;
  padding-bottom: 40px;
  border-radius: 20px;
  margin-top: 24px;
  border: 2px solid #ffffff;
}

.sub_title {
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: $blu4;
  margin-bottom: 30px;
}

.select_delete_flex_wrapper {
  margin-top: 31px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: start;

  & > div {
    width: 359px;
  }
}

.select_wrapper {
  width: 359px;
  margin-top: 31px;
}

.bottom_subtitle {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  .explain_icon {
    margin-left: 8px;
    margin-right: 16px;
  }
}

.spec_text {
  margin-top: 40px;
  color: $text_accent;
}

.specialization_wrapper {
  margin-top: 24.5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16.5px 78px;

  div {
    width: 225px;
  }
}

.service_wrapper {
  margin-top: 41.5px;
  display: flex;
  gap: 9px;
  align-items: center;

  div {
    font-family: Greenwich;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-decoration: underline;
    color: $pink4;
  }

  &:hover {
    cursor: pointer;
  }
}

.bottom_text {
  margin-top: 40px;
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $text_accent;
  display: flex;
  align-items: center;
  gap: 16px;
}

.contacts_wrapper {
  margin-top: 23px;
  display: flex;
  gap: 15px;
  justify-content: space-between;

  .text_delimiter {
    display: flex;
    align-items: center;
    max-width: 22px;
    font-family: Greenwich;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: $grey2;
    padding-top: 36px;
  }
}

.phone_wrapper {
  width: 90%;
}

.social_input_wrapper {
  width: 100%;
}

.radio_group_wrapper {
  margin-top: 24px;
}

.btn_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 94px;
  gap: 12px;

  button {
    width: 386px;
  }

  .cancel_btn {
    width: 260px;
  }
}

.selected_checkboxes_wrapper {
  margin-top: 40px;
  display: flex;
  gap: 20px 32px;
  flex-wrap: wrap;
}

.selected_checkboxes_item {
  display: flex;
  gap: 4px;

  p {
    font-family: Greenwich;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: $pink4;
  }

  img {
    transform: rotate(45deg);
    cursor: pointer;
  }
}

.sub_services_wrapper {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  .sub_service {
    //styleName: Greenwich/Reg 20;
    font-family: Greenwich;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: $pink4;
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 0;
      transform: rotate(45deg);
    }
  }
}

.err_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-top: 8px;
}

@media screen and (max-width: 480px) {
  .page_title {
    margin-bottom: vw(16px);
    font-size: vw(28px);
    line-height: vw(40px);
    font-weight: 600;
  }

  .title {
    margin-bottom: vw(16px);
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .sub_title {
    margin-bottom: vw(24px);
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .checkboxes_wrapper {
    margin: 0;
    padding: vw(32px) vw(16px);
  }

  .select_wrapper {
    margin: 0;
    width: vw(314px);
  }

  .spec_text {
    margin-top: vw(32px);
    font-size: vw(18px);
    line-height: vw(21px);

    .req_sign {
      margin: 0;
    }
  }

  .bottom_subtitle {
    gap: vw(12px);
    div {
      margin: 0;
    }
  }

  .specialization_wrapper {
    margin-top: vw(24px);
    justify-content: space-between;
    gap: vw(20px) vw(5px);

    div {
      width: 49%;
      gap: vw(10px);

      input {
        min-width: vw(15px) !important;
        min-height: vw(15px) !important;
      }

      label {
        font-size: vw(18px);
        line-height: vw(21px);
      }
    }
  }

  .sub_services_wrapper {
    margin-top: vw(26px);
    gap: vw(12px) vw(24px);

    .sub_service {
      font-size: vw(18px);
      line-height: vw(21px);
    }
  }

  .bottom_text {
    margin-top: vw(40px);
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .radio_group_wrapper {
    margin-top: vw(24px);

    div {
      label {
        font-size: vw(20px);
        line-height: vw(20px);
      }
    }
  }

  .contacts_wrapper {
    margin-top: vw(24px);
    flex-direction: column;

    .phone_wrapper {
      width: 100%;
    }

    .text_delimiter {
      max-width: 100%;
      display: flex;
      justify-content: center;
      padding: 0;
      text-align: center;
      font-size: vw(18px);
      line-height: vw(21px);
    }
  }

  .btn_wrapper {
    margin-top: vw(94px);
    margin-bottom: vw(100px);

    .cancel_btn {
      width: vw(210px);
      padding: vw(21px) vw(28px);
      height: vw(62px);
      font-size: vw(18px);
      line-height: vw(18px);
    }

    button {
      width: vw(210px);
      padding: vw(21px) vw(28px);
      height: vw(62px);
      font-size: vw(18px);
      line-height: vw(18px);
    }
  }
}
