@import "../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.salonList_wrapper {
  position: relative;
  padding-top: 78px;

  &::after {
    content: "";
    background: $blu1;
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -999;
  }
}

.page_main_title {
  //styleName: Greenwich/SemiBold 36;
  font-family: Greenwich SemBd;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  color: $text_accent;
  padding: 0 80px;
  margin: 0;
}

.table_wrapper {
  padding: 40px 80px 80px 80px;
}
