@import "./../../../../styles/global-variables.scss";

.status_dropdown {
  width: 170px;
  height: 186px;
  border: 1px solid $blu2;
  background-color: $white;
  box-sizing: border-box;
  border-radius: 8px;
  padding-top: 18px;
  padding-left: 20px;
  text-align: start;
  top: 57px;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;

  .dropdown_item_wrapper {
    position: relative;
    padding-top: 6px;
    padding-bottom: 6px;

    img {
      position: absolute;
      left: 0;
    }

    .dropdown_title {
      margin-left: 20px;
      font-family: "Greenwich";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $text_accent;
    }
  }
}
