@import "../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.main_wrapper {
  width: 100%;
}

.my_select_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
}

.img_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 20px;
    width: 20.6px;
  }
}

.select_label {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $text_accent;
  display: flex;
  justify-content: space-between;
}

.text_icon_wrapper {
  display: flex;
  gap: 8px;
  text-transform: none;
}

.err_text {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
  margin-top: 8px;
}

@media screen and (max-width: 1440px) {
  .my_select_wrapper {
    gap: vw(8px, 1440px);
  }

  .select_label {
    font-size: vw(18px, 1440px);
    line-height: vw(21px, 1440px);
  }

  .text_icon_wrapper {
    gap: vw(8px, 1440px);
  }

  .err_text {
    font-size: vw(18px, 1440px);
    line-height: vw(8px, 1440px);
    margin-top: vw(8px, 1440px);
  }

  .img_wrapper {
    img {
      height: vw(20px, 1440px);
      width: vw(20.6px, 1440px);
    }
  }
}

@media screen and (max-width: 480px) {
  .select_label {
    font-size: vw(18px);
    line-height: vw(21px);
  }

  .err_text {
    font-size: vw(14px);
    line-height: vw(16px);
    margin-top: vw(4px);
  }

  .values {
    * {
      font-size: vw(18px);
      line-height: vw(18px);
    }

    div[role="button"] svg {
      width: vw(24px);
      height: vw(24px);
    }

    img {
      width: vw(20px);
    }
  }

  .img_wrapper {
    img {
      height: 20px;
      width: 20.6px;
    }
  }
}
