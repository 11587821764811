@import "/src/styles/global-variables";
@import "/src/mixins/mixins.scss";

.my_select_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.img_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  img {
    height: 8px;
    width: 14px;
  }
}

.values {
  margin-top: 8px;
}

.select_label {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $text_accent;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  height: 21px;
}

.text_icon_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.err_text {
  font-family: Greenwich, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $red;
  margin-top: 4px;
}

@media screen and (max-width: 480px) {
  .my_select_wrapper {
    .select_label {
      font-size: vw(18px);
      line-height: vw(21px);
      height: vw(21px);
    }

    textarea {
      height: vw(60px);
      font-size: vw(20px);
      line-height: vw(20px);
      border-radius: vw(8px);
    }
  }

  .limit_counter {
    font-size: vw(16px);
    line-height: vw(18px);
  }

  .values {
    margin-top: vw(8px);
    * {
      font-size: vw(18px);
      line-height: vw(18px);
    }

    div[role="button"] svg {
      width: vw(24px);
      height: vw(24px);
    }

    img {
      height: vw(10px);
    }
  }

  .err_text {
    font-size: vw(14px);
    line-height: vw(16px);
    margin-top: vw(4px);
  }

  .img_wrapper {
    margin-right: 0;

    img {
      height: vw(8px);
      width: vw(14px);
    }
  }
}
