@import "/src/mixins/mixins.scss";

.wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(#121212, 0.4);
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.visible_wrapper {
  color: #ffffff;
  z-index: 1000;
  width: 100%;
  max-width: 1440px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
}

.closeBtn {
  background-color: transparent;
  border: none;
  outline: none;
}

.slider {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 75vh;
    object-fit: contain;
  }

  button {
    position: static;

    img {
      width: 54px;
    }
  }
}

.count_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  padding-bottom: 52px;
}

.slides_count {
  //styleName: Greenwich/Med 24;
  font-family: Greenwich;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1439px) {
  .slider {
    img {
      max-width: vw(800px, 1440px);
    }

    button {
      img {
        width: vw(54px, 1440px);
      }
    }
  }

  .visible_wrapper {
    padding: 0px vw(30px, 1440px);
  }

  .count_wrapper {
    gap: vw(26px, 1440px);
    padding-bottom: vw(52px, 1440px);
  }

  .slides_count {
    font-size: vw(24px, 1440px);
  }
}
