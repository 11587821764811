@import "../../styles/global-variables.scss";
@import "/src/mixins/mixins.scss";

.vv_checkbox {
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    //styleName: Greenwich/Reg 20;
    font-family: Greenwich;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    color: $text_accent;
    margin: 0;
  }

  input {
    min-width: 20px;
    min-height: 20px;
  }

  // https://moderncss.dev/pure-css-custom-checkbox-style/
  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    min-width: 18px;
    min-height: 18px;
    border: 2px solid currentColor;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    color: $pink4;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    // box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: $pink4;
  }

  input[type="checkbox"]:checked {
    color: $pink4;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:focus {
    //   outline: max(2px, 0.15em) solid currentColor;
    //   outline-offset: max(2px, 0.15em);
  }

  input[type="checkbox"]:disabled {
    --form-control-color: $grey;
    color: $grey;
    cursor: not-allowed;

    &::before {
      background-color: $grey;
    }
  }
}

@media screen and (max-width: 480px) {
  .vv_checkbox {
    gap: vw(10px);

    label {
      font-size: vw(20px);
      line-height: vw(23px);
    }

    input {
      min-width: vw(20px);
      min-height: vw(20px);
    }

    input[type="checkbox"] {
      min-width: vw(18px);
      min-height: vw(18px);
      border: vw(2px) solid currentColor;
    }
  }
}
