@import "../../styles/global-variables.scss";

.notification_wrapper {
  display: flex;
  flex-direction: column;
  width: 600px;

  .body {
    background-color: $blu1;
    padding: 40px;

    .welcome_text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $text_accent;
      margin-bottom: 40px;
    }

    .complete_profile_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 44px;
      background-color: $white;
      border-radius: 20px;
      padding: 56px 30px;
      margin-bottom: 40px;

      .title {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-family: Arial;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .complete_btn {
      border: 0;
      background-color: $pink4;
      border-radius: 10px;
      height: 50px;
      width: 238px;
      font-family: Verdana;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      color: $white;
    }

    .best_process_text {
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: justified;
      margin-bottom: 40px;
    }

    .with_love {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: $text_accent;
    }
  }
}
