@import "../../../../../styles/global-variables";
@import "/src/mixins/mixins.scss";

.images_wrapper {
  margin-top: 32px;

  .images_header {
    display: flex;
    margin-bottom: 9px;

    .title {
      font-family: Greenwich;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: $text_accent;
      margin-right: 10px;
    }

    .req_sign {
      font-family: "Greenwich";
      font-style: normal;
      font-weight: 400;
      margin-left: 18px;
      font-size: 18px;
      line-height: 21px;
      color: $grey2;
    }
  }
}

.flex_images_wrapper {
  display: flex;
  gap: 40px;
}

.photo_gallery_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .gallery_title {
    font-family: Greenwich;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }
}

.gallery_cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.upload_wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 200px;
}

.camera_wrapper {
  background-color: $white;
  width: 100%;
  height: 200px;
  border-radius: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
}

.upload_label {
  width: 100%;
  border: none;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: $pink4;
  color: $white;
  font-family: Greenwich;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 24px;
  border-radius: 10px;
  text-transform: uppercase;

  input {
    display: none;
  }
}

.photos_wrapper {
  display: flex;
  gap: 45px;
  flex-wrap: wrap;
  margin-bottom: 94px;

  img {
    border-radius: 20px;
  }
}

.photo_wrapper {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;

  img {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    object-fit: cover;
  }

  button {
    width: 100%;
    height: 62px;
    font-family: Greenwich;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $pink4;
    border: 2px solid $pink4;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
}

.btn_bottom_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  button {
    width: 386px;
  }
}

.gallery_photo_err {
  font-family: Greenwich;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: $red;
}

@media screen and (max-width: 480px) {
  .images_wrapper {
    margin-top: vw(40px);

    .images_header {
      font-size: vw(18px);
      line-height: vw(21px);
      margin-bottom: vw(9px);

      div {
        margin-left: vw(8px);
        margin-right: vw(16px);
      }
    }
  }

  .flex_images_wrapper {
    display: flex;
    flex-direction: column;
    gap: vw(40px);
  }

  .photo_gallery_wrapper {
    gap: vw(16px);

    .gallery_title {
      font-size: vw(18px);
      line-height: vw(21px);
    }
  }

  .photo_gallery_wrapper {
    gap: vw(16px);

    .gallery_title {
      font-size: vw(18px);
      line-height: vw(21px);
    }
  }

  .photo_wrapper {
    width: 100%;
    height: 100%;
    gap: vw(16px);

    img {
      height: vw(440px);
      border-radius: vw(20px);
    }

    button {
      width: 100%;
      height: vw(62px);
      font-size: vw(18px);
      border: vw(2px) solid $pink4;
      border-radius: vw(10px);
    }
  }

  .upload_wrapper {
    width: 100%;
    gap: vw(16px);
    margin-bottom: vw(16px);
  }

  .camera_wrapper {
    background-color: $white;
    width: vw(440px);
    height: vw(440px);
    border-radius: vw(20px);

    img {
      width: 100%;
      height: 100%;
      border-radius: vw(20px);
    }
  }

  .upload_label {
    width: 100%;
    font-size: vw(18px);
    padding: vw(22px) vw(32px);
    border-radius: vw(10px);
  }
}
